import './App.scss';
import { AppRoute } from './app/AppRoute';
import { ConfigProvider } from './features/config/ConfigProvider';
import { AuthProvider } from './features/auth/AuthProvider';
import { selectAuthStatus } from 'features/auth/authSlice';
import { useAppSelector } from 'app/hooks';
import SchoolProvider from './features/schools/SchoolProvider';
import UserProvider from 'features/user/UserProvider';
import TopBar from './features/top-bar/TopBar';
import SideBar from './features/side-bar/SideBar';

function App() {
  const auth_status = useAppSelector(selectAuthStatus);

  return (
    <div className="App kd-layout">
      <ConfigProvider>
        <AuthProvider>
            <TopBar />
            {auth_status === 'login' && <SideBar />}
            <AppRoute />
            <SchoolProvider/>
            <UserProvider/>
        </AuthProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
