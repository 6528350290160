import React from 'react';
import { MdAddCircle } from 'react-icons/md';
import { PageButton } from 'components/page/PageButton';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  listGiftCardBatchAction,
  setListPageAction,
  selectListBatch,
  selectListBatchPage,
  displayProductName,
  displayDate,
} from '../giftCardSlice';
import { Link } from 'react-router-dom';
import { route_path } from 'app/route';
import { selectCanWriteGiftCard } from 'features/user/userSlice';

export default function GiftCardBatchListPage() {
  const dispatch = useAppDispatch();

  const canWrite = useAppSelector(selectCanWriteGiftCard);
  const page = useAppSelector(selectListBatchPage);
  const batchList = useAppSelector(selectListBatch);

  React.useEffect(() => {
    dispatch(listGiftCardBatchAction({ ...page }));
  }, [page]);

  const onChangePage = (pageNumber: number) => {
    dispatch(setListPageAction({ page: pageNumber }));
  };

  return (
    <div className="container kd-main">
      <div className="d-flex justify-content-between align-items-center py-3">
        <h2>
          管理禮物卡
        </h2>
        {canWrite &&
        <Link to={route_path.createGiftCardBatch} className="btn btn-primary px-5">
          <MdAddCircle className="mb-1" /> 新增禮物卡
        </Link>
        }
      </div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead className="table-success">
            <tr>
              <th scope="col" className="text-nowrap">#</th>
              <th scope="col" className="text-nowrap">批次名稱</th>
              <th scope="col" className="text-nowrap">產品名稱</th>
              <th scope="col" className="text-nowrap">面額/張</th>
              <th scope="col" className="text-nowrap">發行數</th>
              <th scope="col" className="text-nowrap">已用數</th>
              <th scope="col" className="text-nowrap">序號效期</th>
              <th scope="col" className="text-nowrap">建立日期</th>
              <th scope="col" className="text-nowrap">兌換密碼</th>
              <th scope="col" className="text-nowrap">序號清單</th>
              <th scope="col">備註</th>
            </tr>
          </thead>
          <tbody>
            {batchList.map((batch) => {
              return (
                <tr key={batch.id}>
                  <th scope="row">{batch.index + 1}</th>
                  <td>{batch.name}</td>
                  <td>{displayProductName(batch.productId)}</td>
                  <td>{batch.unit_qty}</td>
                  <td>{batch.qty}</td>
                  <td>{batch.exchanged_qty}</td>
                  <td>{displayDate(batch.expired_ts)}</td>
                  <td>{displayDate(batch.created_ts)}</td>
                  <td className="text-info">{batch.exchanged_password}</td>
                  <td>
                    <Link className="btn btn-outline-primary btn-sm d-block text-nowrap" to={route_path.viewGiftCardBatch.replace(':batch_id', batch.id)}>
                      查看序號
                    </Link>
                  </td>
                  <td className="text-secondary text-smnote">{batch.description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="row d-flex">
        <PageButton
          pageNumber={page.page}
          prePage={page.pre_page}
          totalCount={page.total_count}
          onClick={onChangePage}
        />
      </div>
    </div>
  );
}

