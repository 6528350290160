import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { useAppDispatch, useAppSelector } from 'app/hooks';

import {
  closeTeacherDeleteModalAction,
  deleteSchoolAction,
  deleteSchoolTeacherAction,
  listSchoolTeachersAction,
  selectTeacherDeleteModal,
} from '../schoolSlice';

export function TeacherDeleteModal() {
  const dispatch = useAppDispatch();

  const modal = useAppSelector(selectTeacherDeleteModal);
  const [errMsg, setErrMsg] = React.useState('');

  const onConfirm = async() => {
    let result = await dispatch(deleteSchoolTeacherAction({
      id: modal.schoolId,
      role_binding_id: modal.roleBindingId,
      inviting_user_id: modal.invitingUserId!,
    }));
    if (deleteSchoolTeacherAction.fulfilled.match(result)) {
      dispatch(closeTeacherDeleteModalAction());
      dispatch(listSchoolTeachersAction({ id: modal.schoolId }));
    } else if (deleteSchoolAction.rejected.match(result)) {
      setErrMsg(result.error.name ?? errMsg);
    }
  };

  const onHide = () => {
    dispatch(closeTeacherDeleteModalAction());
  };

  return (
    <Modal show={modal.visible} onHide={onHide}>
      <Modal.Header className="bg-danger text-light">
        <h5>確認要刪除老師({modal.teacherName})？</h5>
      </Modal.Header>
      <Modal.Body>
        <p>
          確定要刪除嗎？
        </p>
        <span className="text-danger">{errMsg}</span>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
        <button className="btn btn-primary" onClick={onConfirm}>確定</button>
        <button className="btn btn-secondary" onClick={onHide}>取消</button>
      </Modal.Footer>
    </Modal>
  );
}
