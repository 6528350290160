import { Route, Switch } from 'react-router';

import { route_path } from './route';
import ParentListPage from 'features/parents/ParentListPage';
import SchoolListPage from 'features/schools/SchoolListPage';
import TeacherListPage from 'features/schools/TeacherListPage';
import Login from 'features/auth/Login';
import Home from 'features/home/Home';
import GiftCardBatchListPage from 'features/gift-card/pages/GiftCardBatchListPage';
import CreateGiftCardBatchPage from 'features/gift-card/pages/CreateGiftCardBatchPage';
import GiftCardBatchPage from 'features/gift-card/pages/GiftCardListPage';


export function AppRoute() {
  return (
    <Switch>
      <Route exact strict path={route_path.login} component={Login} />
      <Route exact strict path={route_path.parents} component={ParentListPage} />
      <Route exact strict path={route_path.schools} component={SchoolListPage} />
      <Route exact strict path={route_path.school_teachers} component={TeacherListPage} />
      <Route exact strict path={route_path.giftCardBatch} component={GiftCardBatchListPage} />
      <Route exact strict path={route_path.createGiftCardBatch} component={CreateGiftCardBatchPage} />
      <Route exact strict path={route_path.viewGiftCardBatch} component={GiftCardBatchPage} />
      <Route component={Home} />
    </Switch>
  );
}
