import axios from 'axios';

import { AppConfig } from '../types';
import { toSerializedError } from '../errors/utils';

export async function getAppConfig() {
  try {
    let res = await axios.get<AppConfig>('/api/config.json');
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}
