import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';

import { RootState, AppThunkConfig } from 'app/store';
import { bindAPI } from 'app/auth';
import { User, UserSchool } from 'types';
import { logoutAction } from 'features/auth/authSlice';
import { GetProfileResponse, getProfile, ListUserSchoolsResponse, listUserSchools } from 'handlers';

export type UserState = {
  profile?: User;
  scopeMap: {[scope: string]: boolean;};
  schools: UserSchool[];
};

const initialState: UserState = {
  scopeMap: {},
  schools: [],
};

export const getProfileAction = createAsyncThunk<
  GetProfileResponse, void, AppThunkConfig
>(
  'user/getProfile',
  (arg, thunkAPI) => bindAPI(getProfile, thunkAPI)(arg),
);

export const listUserSchoolsAction = createAsyncThunk<
  ListUserSchoolsResponse, void, AppThunkConfig
>(
  'user/listSchools',
  (arg, thunkAPI) => bindAPI(listUserSchools, thunkAPI)(arg),
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfileAction.fulfilled, (state, action) => {
        state.profile = {
          name: action.payload.name,
          user_id: action.payload.user_id,
          scope: action.payload.scope,
          email: action.payload.email,
          email_verified: action.payload.email_verified,
        };
        state.scopeMap = action.payload.scope.split(' ').reduce((result, scope) => {
          return {
            ...result,
            [scope]: true,
          };
        }, {});
      })
      .addCase(listUserSchoolsAction.fulfilled, (state, action) => {
        state.schools = action.payload.data;
      })
      .addCase(logoutAction, () => initialState);
  },
});

export const selectUserProfile = (state: RootState) => state.user.profile;

export const selectCanReadParents = (state: RootState) => {
  return state.user.scopeMap['keedle/parents.read'] ?? state.user.scopeMap['keedle/parents.read_write'] ?? false;
};

export const selectCanReadSchools = (state: RootState) => {
  if (state.user.scopeMap['keedle/schools.read'] ?? state.user.scopeMap['keedle/schools.read_write']) {
    return true;
  }
  for (let school of state.user.schools) {
    if (school.role_ids.includes('keedle/school_admin')) {
      return true;
    }
  }
  return false;
};

export const selectCanReadSchoolInfo = (state: RootState, schoolId: string) => {
  if (state.user.scopeMap['keedle/schools.read'] ?? state.user.scopeMap['keedle/schools.read_write']) {
    return true;
  }
  let school = state.user.schools.find(school => school.id === schoolId);
  return school?.role_ids.includes('keedle/school_admin') ?? false;
}

export const selectCanWriteSchool = (state: RootState) => {
  if (state.user.scopeMap['keedle/schools.read_write']) {
    return true;
  }
  return false;
};

export const selectSchoolWithRoles = (state: RootState) => {
  return state.user.schools.filter(school => {
    return school.role_ids.includes('keedle/school_admin');
  });
};

export const selectHasAnyScopes = (state: RootState, ...args: string[]): boolean => {
  for (let scope of args) {
    if (state.user.scopeMap[scope]) {
      return true;
    }
  }
  return false;
}

export const selectCanReadGiftCardBatch = (state: RootState) => {
  return selectHasAnyScopes(state, 'keedle/gift_cards.read', 'keedle/gift_cards.read_write');
};

export const selectCanWriteGiftCard = (state: RootState) => {
  return selectHasAnyScopes(state, 'keedle/gift_cards.read_write');
}
