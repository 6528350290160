import {UseFormRegisterReturn} from 'react-hook-form';

import {ValueItem} from './ValueItem';

type Props = {
  title: string;
  name: string;
  mode?: 'read'|'write';
  type?: 'text'|'number';
  value?: string;
  placeholder?: string;
  errorMsg?: string;
  regForm?: () => UseFormRegisterReturn;
};

export function TextItem({
  title, mode = 'read', type = 'text', name, placeholder, errorMsg, value, regForm,
}: Props) {
  if (mode === 'write') {
    return (
      <div className="row py-1">
        <label htmlFor={name} className="col-5 col-form-label">{title}</label>
        <div className="col-7">
          <input type={type} id={name} className="form-control"
            { ...regForm?.() }
            placeholder={placeholder}
          />
          <span className="text-danger text-smnote">{errorMsg}</span>

        </div>
      </div>
    );
  }
  return (
    <ValueItem title={title} name={name} value={value}/>
  );
}
