import {
  createAsyncThunk,
  createSlice,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState, AppThunkConfig } from '../../app/store';
import { bindAPI } from '../../app/auth'
import {
  ListParentsQuery,
  ListParentsResponse,
  listParents,
  GetParentParams,
  GetParentResponse,
  getParent,
  UpdateParentParams,
  UpdateParentResponse,
  updateParent,
} from '../../handlers';
import { Parent, ParentDetail, PagedQuery } from 'types';

export const listParentsAction = createAsyncThunk<
  ListParentsResponse, ListParentsQuery, AppThunkConfig
>(
  'parent/List',
  (arg, thunkAPI) => bindAPI(listParents, thunkAPI)(arg),
);

export const getParentAction = createAsyncThunk<
  GetParentResponse, GetParentParams, AppThunkConfig
>(
  'parent/Get',
  (arg, thunkAPI) => bindAPI(getParent, thunkAPI)(arg),
);

export const updateParentAction = createAsyncThunk<
  UpdateParentResponse, UpdateParentParams, AppThunkConfig
>(
  'parent/Update',
  (arg, thunkAPI) => bindAPI(updateParent, thunkAPI)(arg),
);


export type ParentState = {
  list_map: {
    [user_id: string]: Parent & {
      quota?: ParentDetail['quota'];
    };
  };
  list_ids: string[];
  list_page: {
    page: number;
    pre_page: number;
    total_count: number;
  };
  parent_modal: {
    visible: boolean;
    parent_id: string;
  };
  error?: SerializedError;
};

const initialState: ParentState = {
  list_map: {},
  list_ids: [],
  list_page: {
    page: 1,
    pre_page: 10,
    total_count: 0,
  },
  parent_modal: {
    visible: false,
    parent_id: '',
  },
};

export const parentSlice = createSlice({
  name: 'parent',
  initialState,
  reducers: {
    setListPageAction(state: ParentState, action: PayloadAction<Partial<PagedQuery>>) {
      state.list_page.page = action.payload.page ?? state.list_page.page;
      state.list_page.pre_page = action.payload.pre_page ?? state.list_page.pre_page;
    },
    openParentModalAction(state: ParentState, action: PayloadAction<string>) {
      state.parent_modal.visible = true;
      state.parent_modal.parent_id = action.payload;
    },
    closeParentModalAction(state: ParentState, action: PayloadAction<void>) {
      state.parent_modal.visible = false;
      state.parent_modal.parent_id = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listParentsAction.pending, (state, action) => {
        state.list_page.page = action.meta.arg.page ?? initialState.list_page.page;
        state.list_page.pre_page = action.meta.arg.pre_page ?? initialState.list_page.pre_page;
      })
      .addCase(listParentsAction.fulfilled, (state, action) => {
        state.list_ids = action.payload.data.map(parent => parent.user_id);
        state.list_page.total_count = action.payload.total_count;
        state.list_map = action.payload.data.reduce((result, parent, index) => {
          return {
            ...result,
            [parent.user_id]: parent,
          };
        }, {});
      })
      .addCase(listParentsAction.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(getParentAction.fulfilled, (state, action) => {
        state.list_map[action.meta.arg.parent_id] = action.payload;
      });
  },
});

export const { setListPageAction, openParentModalAction, closeParentModalAction } = parentSlice.actions;

export const selectListParents = (state: RootState) => {
  let page = selectListParentsPage(state);
  return state.parent.list_ids.map((id, index) => {
    return {
      ...state.parent.list_map[id],
      index: page.pre_page * (page.page - 1) + index
    };
  });
};

export const selectListParentsPage = (state: RootState) => {
  return state.parent.list_page;
};

export const selectParentInfo = (state: RootState, parent_id: string) => {
  if (!state.parent.list_ids.includes(parent_id)) {
    return;
  }
  return state.parent.list_map[parent_id];
}

export const selectParentModal = (state: RootState) => {
  let modal = state.parent.parent_modal;
  return {
    ...modal,
    parent: state.parent.list_map[modal.parent_id],
  };
};
