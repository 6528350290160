import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

import { TextItem } from 'components/input/TextItem';
import { SelectItem } from 'components/input/SelectItem';

import { ParentDetail, EntityRegisterOptions } from 'types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getParentAction, closeParentModalAction, selectParentModal, selectParentInfo, updateParentAction } from '../parentSlice';

type FormData = Required<Pick<ParentDetail, 'quota' | 'state'>>;

const fieldOptions: EntityRegisterOptions<FormData> = {
  quota: {
    EBAS_3TO6_PARENTS: {
      required: '必填',
    },
    EBAS_OVER6_PARENTS: {
      required: '必填',
    },
    AAIS_3TO6_PARENTS: {
      required: '必填',
    },
    AAIS_OVER6_PARENTS: {
      required: '必填',
    },
    EBAS_PARENTS: {
      required: '必填',
    },
    AAIS_PARENTS: {
      required: '必填',
    },
  },
};

const initialForm: FormData = {
  quota: {
    EBAS_3TO6_PARENTS: 0,
    EBAS_OVER6_PARENTS: 0,
    AAIS_3TO6_PARENTS: 0,
    AAIS_OVER6_PARENTS: 0,
    EBAS_PARENTS: 0,
    AAIS_PARENTS: 0,
  },
  state: 'enabled',
};

const stateOptions = [
  { title: '啟用', value: 'enabled' },
  { title: '停用', value: 'disabled' },
];

export function ParentQuotaModal() {
  const dispatch = useAppDispatch();
  const modal = useAppSelector(selectParentModal);
  const parent = useAppSelector((state) => selectParentInfo(state, modal.parent_id));
  const { handleSubmit, register, reset, formState: { errors } } = useForm({
    defaultValues: initialForm,
  });
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    if (modal.parent_id) {
      dispatch(getParentAction({ parent_id: modal.parent_id }));
    }
  }, [modal.parent_id]);

  React.useEffect(() => {
    reset(parent);
  }, [parent]);

  const onSubmit = async (newData: FormData) => {
    let result = await dispatch(updateParentAction({
      parent_id: modal.parent_id,
      body: newData,
    }));
    if (updateParentAction.fulfilled.match(result)) {
      dispatch(closeParentModalAction());
    } else {
      setErrMsg(result.error.name ?? errMsg);
    }
  };

  const onClose = () => {
    dispatch(closeParentModalAction());
  };
  return (
    <Modal show={modal.visible} onHide={onClose}>
      <Modal.Header className="bg-primary text-light">
        <h5>家長: {parent?.name}</h5>
      </Modal.Header>
      <Modal.Body>
        <form className="container-lg" onSubmit={handleSubmit(onSubmit)}>
          <TextItem
            title="情緒行為-幼兒版點數"
            name="3to6_parent"
            mode="write"
            type="number"
            regForm={() => register('quota.EBAS_3TO6_PARENTS', fieldOptions.quota?.EBAS_3TO6_PARENTS)}
            errorMsg={errors.quota?.EBAS_3TO6_PARENTS?.message}
          />
          <TextItem
            title="情緒行為-學齡版點數"
            name="over6_parent"
            mode="write"
            type="number"
            regForm={() => register('quota.EBAS_OVER6_PARENTS', fieldOptions.quota?.EBAS_OVER6_PARENTS)}
            errorMsg={errors.quota?.EBAS_OVER6_PARENTS?.message}
          />
          <TextItem
            title="情緒行為-組合版點數"
            name="combined_parent"
            mode="write"
            type="number"
            regForm={() => register('quota.EBAS_PARENTS', fieldOptions.quota?.EBAS_PARENTS)}
            errorMsg={errors.quota?.EBAS_PARENTS?.message}
          />
          <TextItem
            title="能力現況-幼兒版點數"
            name="3to6_parent"
            mode="write"
            type="number"
            regForm={() => register('quota.AAIS_3TO6_PARENTS', fieldOptions.quota?.AAIS_3TO6_PARENTS)}
            errorMsg={errors.quota?.AAIS_3TO6_PARENTS?.message}
          />
          <TextItem
            title="能力現況-學齡版點數"
            name="over6_parent"
            mode="write"
            type="number"
            regForm={() => register('quota.AAIS_OVER6_PARENTS', fieldOptions.quota?.AAIS_OVER6_PARENTS)}
            errorMsg={errors.quota?.AAIS_OVER6_PARENTS?.message}
          />
          <TextItem
            title="能力現況-組合版點數"
            name="combined_parent"
            mode="write"
            type="number"
            regForm={() => register('quota.AAIS_PARENTS', fieldOptions.quota?.AAIS_PARENTS)}
            errorMsg={errors.quota?.AAIS_PARENTS?.message}
          />
          <SelectItem
            title="狀態"
            name="state"
            mode="write"
            options={stateOptions}
            regForm={() => register('state', fieldOptions.state)}
            errorMsg={errors.state?.message}
          />
          {errMsg &&
            <span className="text-danger">{errMsg}</span>
          }
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-outline-success">存檔</button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
