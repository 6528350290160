import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectAuthStatus } from 'features/auth/authSlice';
import { selectUserProfile } from 'features/user/userSlice';
import { route_path } from 'app/route';
import { logoutAction } from 'features/auth/authSlice';
import { FaSignOutAlt } from "react-icons/fa";


export default function TopBar() {
  const dispatch = useAppDispatch();
  const auth_status = useAppSelector(selectAuthStatus);
  const user = useAppSelector(selectUserProfile);
  return (
    <nav className="kd-nav navbar navbar-expand-lg navbar-dark bg-keedleblue">
      <div className="container-fluid ms-2 me-5 my-1">
        <NavLink className="navbar-brand"
          to={auth_status === 'login' ? '/' : '/login'}>
        籽樂教育 Keedle Admin Portal
        </NavLink>
          {auth_status === 'login' &&
          <div className="d-flex align-items-center">
            <span className="text-white pe-4 text-14">目前登入帳號：{user?.name}</span>
            <Link to={route_path.login} onClick={() => dispatch(logoutAction())} className="btn btn-outline-light btn-sm"><FaSignOutAlt className="mb-1 me-2"/>登出</Link>
          </div>
          }
      </div>
    </nav>
  );
}
