import React from 'react';

import {useAppSelector, useAppDispatch} from 'app/hooks';
import {selectAuthStatus} from 'features/auth/authSlice';
import {confirmSchoolInvitingAction} from './schoolSlice';

export default function SchoolProvider() {
  const dispatch = useAppDispatch();
  let authState = useAppSelector(selectAuthStatus);

  React.useEffect(() => {
    if (authState === 'login') {
      dispatch(confirmSchoolInvitingAction());
    }
  }, [authState]);

  return (
    <React.Fragment/>
  );
}
