import { useState, MouseEvent } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { useForm, RegisterOptions } from 'react-hook-form';
import { useHistory } from "react-router-dom";

import { FcGoogle } from 'react-icons/fc';
import { useAppDispatch } from '../../app/hooks';
import { route_path } from '../../app/route';
import {
  loginWithPasswordAction,
  LoginWithPasswordParams,
  loginByOAuth,
} from './authSlice';

const initialState: LoginWithPasswordParams = {
  username: '',
  password: '',
};

const field_options: Partial<Record<keyof LoginWithPasswordParams, RegisterOptions>> = {
  username: { 
    required: 'Username is required',
  },
  password: {
    required: 'Password is required',
  },
};

export default function Login() {
  const dispatch = useAppDispatch();
  let history = useHistory();
  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: initialState,
  });
  const [api_error, setApiError] = useState<SerializedError|undefined>();

  const onLogin = async(data: typeof initialState) => {
    let login_result = await dispatch(loginWithPasswordAction(data));
    if (loginWithPasswordAction.rejected.match(login_result)) {
      setApiError(login_result.error);
      const subscription = watch(() => {
        setApiError(undefined);
        subscription.unsubscribe();
      });
    }
    if (loginWithPasswordAction.fulfilled.match(login_result)) {
      history.push(route_path.home);
    }
  };

  const onLoginGoogle = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(loginByOAuth({ provider: 'google' }));
  };

  return (
    <div className="container kd-login">
      <div className="row justify-content-center align-items-center mt-5">
        <form className="col col-md-5 border border-secondary card my-5 px-4 py-2" onSubmit={handleSubmit(onLogin)}>
          <div className="card-body">
            <div className="d-grid gap-2 my-3">
              <span className="fw-bold">快速登入(建議登入方式)</span>
              <button className="btn btn-outline-primary" onClick={onLoginGoogle}>
                <FcGoogle className="me-2 mb-1" />
                透過 Google 帳號登入
              </button>
            </div>
            <div>
              <span className="d-flex justify-content-center text-secondary border-top pt-2">或</span>
            </div>
            <div className="mb-3">
            

              <label htmlFor="account" className="form-label">Account</label>
              <input
                disabled
                className="form-control"
                id="account"
                placeholder="此功能尚未開放"
                { ...register('username', field_options.username) }
              />
              <span className="text-danger">{errors.username?.message}</span>
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input
                disabled
                type="password"
                className="form-control"
                id="password"
                placeholder="此功能尚未開放"
                { ...register('password', field_options.password) }
              />
              <span className="text-danger">{errors.password?.message}</span>
            </div>
            <span className="text-danger">{api_error?.message ?? api_error?.name}</span>
            <div className="d-grid gap-2 my-4">
              <button type="submit" disabled className="btn btn-outline-primary">登入 Login</button>
            </div>
          </div>
        </form>
        <span className="text-secondary d-flex justify-content-center fixed-bottom mb-3">Copyright © 2022 籽樂教育. All rights reserved.</span>
      </div>
    </div>
  );
}
