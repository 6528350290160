import { push, replace } from 'connected-react-router';
import { generatePath, match, matchPath, useRouteMatch, useLocation } from 'react-router';

export enum route_path {
  home = '/',
  login = '/login',
  parents = '/parents',
  schools = '/schools',
  school_teachers = '/schools/:school_id/teachers',
  giftCardBatch = '/gift-card-batch',
  createGiftCardBatch = '/gift-card-batch/create',
  viewGiftCardBatch = '/gift-card-batch/:batch_id',
};
export { generatePath, push, replace, matchPath, useRouteMatch, useLocation };
export type { match };
