import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { QRCodeSVG } from 'qrcode.react';

import { TextItem } from 'components/input/TextItem';
import { SelectItem } from 'components/input/SelectItem';
import { CheckboxItem } from 'components/input/CheckboxItem';

import { SchoolDetail, EntityRegisterOptions } from 'types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  closeSchoolModalAction,
  createSchoolAction,
  updateSchoolAction,
  listSchoolsAction,
  selectSchoolModal,
  selectSchoolInfo,
  selectSchoolInviteCodeLink,
} from '../schoolSlice';

type FormData = Required<Pick<SchoolDetail, 'name' | 'state' | 'quota' | 'invite_code_is_enabled'>>;

const fieldOptions: EntityRegisterOptions<FormData> = {
  name: {
    required: '必填',
  },
  state: {
    required: '必填',
  },
  quota: {
    EBAS_3TO6_SCHOOL: {
      required: '必填',
    },
    EBAS_OVER6_SCHOOL: {
      required: '必填',
    },
    AAIS_3TO6_SCHOOL: {
      required: '必填',
    },
    AAIS_OVER6_SCHOOL: {
      required: '必填',
    },
    EBAS_SCHOOL: {
      required: '必填',
    },
    AAIS_SCHOOL: {
      required: '必填',
    },
  },
};

const initialForm: FormData = {
  name: '',
  state: 'enabled',
  quota: {
    EBAS_3TO6_SCHOOL: 0,
    EBAS_OVER6_SCHOOL: 0,
    AAIS_3TO6_SCHOOL: 0,
    AAIS_OVER6_SCHOOL: 0,
    EBAS_SCHOOL: 0,
    AAIS_SCHOOL: 0,
  },
  invite_code_is_enabled: false,
};

const stateOptions = [
  { title: '啟用', value: 'enabled' },
  { title: '停用', value: 'disabled' },
];

export function SchoolModal() {
  const dispatch = useAppDispatch();
  const modal = useAppSelector(selectSchoolModal);
  const school = useAppSelector((state) => selectSchoolInfo(state, modal.schoolId));
  const inviteUri = useAppSelector((state) => selectSchoolInviteCodeLink(state, modal.schoolId));
  const { handleSubmit, register, reset, formState: { errors } } = useForm({
    defaultValues: initialForm,
  });
  const [errMsg, setErrMsg] = React.useState('');
  const mode = modal.type === 'view' ? 'read' : 'write';

  React.useEffect(() => {
    reset(school);
  }, [school]);

  const onSubmit = async (newData: FormData) => {
    if (modal.type === 'create') {
      let result = await dispatch(createSchoolAction(newData));
      if (createSchoolAction.fulfilled.match(result)) {
        dispatch(closeSchoolModalAction());
        dispatch(listSchoolsAction());
      } else {
        setErrMsg(result.error.name ?? errMsg);
      }
    } else if (modal.type === 'update') {
      let result = await dispatch(updateSchoolAction({
        id: modal.schoolId,
        ...newData,
      }));
      if (updateSchoolAction.fulfilled.match(result)) {
        dispatch(closeSchoolModalAction());
        dispatch(listSchoolsAction());
      } else {
        setErrMsg(result.error.message ?? result.error.name ?? 'Unknown');
      }
    }
  };

  const onClose = () => {
    dispatch(closeSchoolModalAction());
  };
  return (
    <Modal show={modal.visible} onHide={onClose}>
      <form className="container-lg" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <TextItem
            title="學校名稱"
            name="name"
            mode={mode}
            regForm={() => register('name', fieldOptions.name)}
            value={school?.name}
            errorMsg={errors.name?.message}
          />
          <TextItem
            title="情緒行為-幼兒版點數"
            name="3to6_school"
            mode={mode}
            type="number"
            regForm={() => register('quota.EBAS_3TO6_SCHOOL', fieldOptions.quota?.EBAS_3TO6_SCHOOL)}
            value={school?.quota?.EBAS_3TO6_SCHOOL.toString()}
            errorMsg={errors.quota?.EBAS_3TO6_SCHOOL?.message}
          />
          <TextItem
            title="情緒行為-學齡版點數"
            name="over6_school"
            mode={mode}
            type="number"
            regForm={() => register('quota.EBAS_OVER6_SCHOOL', fieldOptions.quota?.EBAS_OVER6_SCHOOL)}
            value={school?.quota?.EBAS_OVER6_SCHOOL.toString()}
            errorMsg={errors.quota?.EBAS_OVER6_SCHOOL?.message}
          />
          <TextItem
            title="情緒行為-組合版點數"
            name="combined_school"
            mode={mode}
            type="number"
            regForm={() => register('quota.EBAS_SCHOOL', fieldOptions.quota?.EBAS_SCHOOL)}
            value={school?.quota?.EBAS_SCHOOL.toString()}
            errorMsg={errors.quota?.EBAS_SCHOOL?.message}
          />
          <TextItem
            title="能力現況-幼兒版點數"
            name="3to6_school"
            mode={mode}
            type="number"
            regForm={() => register('quota.AAIS_3TO6_SCHOOL', fieldOptions.quota?.AAIS_3TO6_SCHOOL)}
            value={school?.quota?.AAIS_3TO6_SCHOOL.toString()}
            errorMsg={errors.quota?.AAIS_3TO6_SCHOOL?.message}
          />
          <TextItem
            title="能力現況-學齡版點數"
            name="over6_school"
            mode={mode}
            type="number"
            regForm={() => register('quota.AAIS_OVER6_SCHOOL', fieldOptions.quota?.AAIS_OVER6_SCHOOL)}
            value={school?.quota?.AAIS_OVER6_SCHOOL.toString()}
            errorMsg={errors.quota?.AAIS_OVER6_SCHOOL?.message}
          />
          <TextItem
            title="能力現況-組合版點數"
            name="combined_school"
            mode={mode}
            type="number"
            regForm={() => register('quota.AAIS_SCHOOL', fieldOptions.quota?.AAIS_SCHOOL)}
            value={school?.quota?.AAIS_SCHOOL.toString()}
            errorMsg={errors.quota?.AAIS_SCHOOL?.message}
          />
          <CheckboxItem
            title="啟用邀請碼"
            name="invite_code_is_enabled"
            mode={mode}
            regForm={() => register('invite_code_is_enabled')}
            value={school?.invite_code_is_enabled}
          />
          {school?.invite_code_is_enabled && school?.invite_code &&
            <div className="d-flex justify-content-center py-1 flex-wrap">
              <QRCodeSVG value={inviteUri}/>
              <a href={inviteUri} target="_blank" rel="noreferrer noopener" className="text-smnote my-3 p-2 rounded bg-light">{inviteUri}</a>
            </div>
          }
          <SelectItem
            title="狀態"
            name="state"
            mode={mode}
            options={stateOptions}
            regForm={() => register('state', fieldOptions.state)}
            value={school?.state}
            errorMsg={errors.state?.message}
          />
          {errMsg &&
            <span className="text-danger">{errMsg}</span>
          }
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {mode === 'write' &&
          <button type="submit" className="btn btn-outline-success">存檔</button>
          }
        </Modal.Footer>
      </form>
    </Modal>
  );
}
