import {
  createAsyncThunk,
  createSlice,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState, AppThunkConfig } from 'app/store';
import { bindAPI } from 'app/auth'
import {
  ListGiftCardBatchQuery,
  ListGiftCardBatchResponse,
  listGiftCardBatch,
  ListGiftCardQuery,
  ListGiftCardResponse,
  listGiftCard,
  CreateGiftCardBatchBody,
  CreateGiftCardBatchResponse,
  createGiftCardBatch,
  DisableGiftCardQuery,
  DisableGiftCardResponse,
  disableGiftCard,
} from 'handlers';
import { PagedQuery, GiftCardBatch, GiftCard } from 'types';

export const getGiftCardBatchAction = createAsyncThunk<
  GiftCardBatch, { id: string }, AppThunkConfig
>(
  'giftCardBatch/Get',
  async (arg, thunkAPI) => {
    const result = await bindAPI(listGiftCardBatch, thunkAPI)({ id: [arg.id] });
    if (result.total_count !== 1) {
      throw Error('not_found');
    }
    return result.data[0];
  },
);

export const listGiftCardBatchAction = createAsyncThunk<
  ListGiftCardBatchResponse, ListGiftCardBatchQuery, AppThunkConfig
>(
  'giftCardBatch/List',
  (arg, thunkAPI) => bindAPI(listGiftCardBatch, thunkAPI)(arg),
);

export const listGiftCardAction = createAsyncThunk<
  ListGiftCardResponse, ListGiftCardQuery, AppThunkConfig
>(
  'giftCard/List',
  (arg, thunkAPI) => bindAPI(listGiftCard, thunkAPI)(arg),
);

export const createGiftCardBatchAction = createAsyncThunk<
  CreateGiftCardBatchResponse, CreateGiftCardBatchBody, AppThunkConfig
>(
  'giftCardBatch/Create',
  (arg, thunkAPI) => bindAPI(createGiftCardBatch, thunkAPI)(arg),
);

export const disableGiftCardBatchAction = createAsyncThunk<
  DisableGiftCardResponse, DisableGiftCardQuery, AppThunkConfig
>(
  'giftCard/Disable',
  (arg, thunkAPI) => bindAPI(disableGiftCard, thunkAPI)(arg),
);


export type GiftCardState = {
  batchListMap: {
    [id: string]: GiftCardBatch;
  };
  batchListIds: string[];
  batchListPage: {
    page: number;
    pre_page: number;
    total_count: number;
  };
  listMap: {
    [no: string]: GiftCard;
  };
  listNos: string[];
  error?: SerializedError;
};

const initialState: GiftCardState = {
  batchListMap: {},
  batchListIds: [],
  batchListPage: {
    page: 1,
    pre_page: 10,
    total_count: 0,
  },
  listMap: {},
  listNos: [],
};

export const giftCardSlice = createSlice({
  name: 'giftCard',
  initialState,
  reducers: {
    setListPageAction(state: GiftCardState, action: PayloadAction<Partial<PagedQuery>>) {
      state.batchListPage.page = action.payload.page ?? state.batchListPage.page;
      state.batchListPage.pre_page = action.payload.pre_page ?? state.batchListPage.pre_page;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listGiftCardBatchAction.pending, (state, action) => {
        state.batchListPage.page = action.meta.arg.page ?? initialState.batchListPage.page;
        state.batchListPage.pre_page = action.meta.arg.pre_page ?? initialState.batchListPage.pre_page;
      })
      .addCase(listGiftCardBatchAction.fulfilled, (state, action) => {
        state.batchListIds = action.payload.data.map(row => row.id);
        state.batchListPage.total_count = action.payload.total_count;
        state.batchListMap = action.payload.data.reduce((result, row, index) => {
          return {
            ...result,
            [row.id]: row,
          };
        }, {});
      })
      .addCase(listGiftCardBatchAction.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(getGiftCardBatchAction.fulfilled, (state, action) => {
        state.batchListMap[action.payload.id] = action.payload;
      })
      .addCase(getGiftCardBatchAction.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(listGiftCardAction.pending, (state, action) => {
        state.listNos = [];
        state.listMap = {};
      })
      .addCase(listGiftCardAction.fulfilled, (state, action) => {
        state.listNos = action.payload.data.map(row => row.no);
        state.listMap = action.payload.data.reduce((result, row, index) => {
          return {
            ...result,
            [row.no]: row,
          };
        }, {});
      })
      .addCase(listGiftCardAction.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

export const { setListPageAction } = giftCardSlice.actions;

export const selectListBatch = (state: RootState) => {
  let page = selectListBatchPage(state);
  return state.giftCard.batchListIds.map((id, index) => {
    return {
      ...state.giftCard.batchListMap[id],
      index: page.pre_page * (page.page - 1) + index
    };
  });
};

export const selectListBatchPage = (state: RootState) => {
  return state.giftCard.batchListPage;
};

export const selectGetBatchInfo = (state: RootState, batch_id: string): GiftCardBatch|undefined => {
  return state.giftCard.batchListMap[batch_id];
};

export const selectListGiftCards = (state: RootState) => {
  return state.giftCard.listNos.map((no) => {
    return state.giftCard.listMap[no];
  });
};

export const productOptions = [
  { title: '情緒行為(幼兒版)', value: 'EBAS_3TO6_PARENTS' },
  { title: '情緒行為(學齡版)', value: 'EBAS_OVER6_PARENTS' },
  { title: '情緒行為(組合版)', value: 'EBAS_PARENTS' },
  { title: '能力現況(幼兒版)', value: 'AAIS_3TO6_PARENTS' },
  { title: '能力現況(學齡版)', value: 'AAIS_OVER6_PARENTS' },
  { title: '能力現況(組合版)', value: 'AAIS_PARENTS' },
];

export function displayProductName(productId: string) {
  return productOptions.find(product => product.value === productId)?.title ?? 'unknown';
}

export function displayDate(ts: number | undefined) {
  if (ts === undefined) {
    return '';
  }
  const date = new Date(ts);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function displayDateTime(ts: number | undefined) {
  if (ts === undefined) {
    return '';
  }
  const date = new Date(ts);
  return date.toLocaleString();
}
