import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { useAppDispatch, useAppSelector } from 'app/hooks';

import {
  closeSchoolDeleteModalAction,
  deleteSchoolAction,
  listSchoolsAction,
  selectSchoolDeleteModal,
  selectSchoolInfo,
} from '../schoolSlice';

export function SchoolDeleteModal() {
  const dispatch = useAppDispatch();

  const modal = useAppSelector(selectSchoolDeleteModal);
  const school = useAppSelector((state) => selectSchoolInfo(state, modal.schoolId));
  const [errMsg, setErrMsg] = React.useState('');

  const onConfirm = async() => {
    let result = await dispatch(deleteSchoolAction({ id: modal.schoolId }));
    if (deleteSchoolAction.fulfilled.match(result)) {
      dispatch(closeSchoolDeleteModalAction());
      dispatch(listSchoolsAction());
    } else if (deleteSchoolAction.rejected.match(result)) {
      setErrMsg(result.error.name ?? errMsg);
    }
  };

  const onHide = () => {
    dispatch(closeSchoolDeleteModalAction());
  };

  return (
    <Modal show={modal.visible} onHide={onHide}>
      <Modal.Header className="bg-danger text-light">
        <h5>確認要刪除學校({school?.name})？</h5>
      </Modal.Header>
      <Modal.Body>
        <p>
          刪除後不可復原，確定要刪除嗎？
        </p>
        <span className="text-danger">{errMsg}</span>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
        <button className="btn btn-primary" onClick={onConfirm}>確定</button>
        <button className="btn btn-secondary" onClick={onHide}>取消</button>
      </Modal.Footer>
    </Modal>
  );
}
