import {
  createAsyncThunk,
  createAction,
  createSlice,
  Dispatch,
  AnyAction,
  Middleware,
  MiddlewareAPI,
  SerializedError,
} from '@reduxjs/toolkit';
import {
  REHYDRATE,
  RehydrateAction,
  PersistConfig,
} from 'redux-persist';

import { RootState, AppThunkConfig, AppDispatch, ThunkAPI } from 'app/store';
import { push, route_path } from 'app/route'; 
import { ErrorName } from 'errors/error-name';
import { toSerializedError } from 'errors/utils';
import { generateRandomString } from 'common/generate-random-string';
import { selectAppConfig } from 'features/config/configSlice';
import {
  createToken,
  PasswordLoginBody,
  GetTokenInfoResponse,
  getTokenInfo,
  LoginResponse,
} from 'handlers';
import { AuthHeaders, ALL_SCOPES } from 'types';

export type LoginWithPasswordParams = Omit<PasswordLoginBody, 'grant_type'|'client_id'>;
export type LoginWithPasswordResponse = LoginResponse;
export const loginWithPasswordAction = createAsyncThunk<
  LoginWithPasswordResponse, LoginWithPasswordParams, AppThunkConfig
>(
  'auth/loginWithPassword',
  (body, { getState }) => {
    const config = selectAppConfig(getState());
    return createToken(config, {
      ...body,
      grant_type: 'password',
      client_id: config.client_id,
    });
  }
);

export type LoginWithAuthorizationCodeResponse = LoginResponse;
export const loginWithAuthorizationCodeAction = createAsyncThunk<
  LoginWithAuthorizationCodeResponse, void, AppThunkConfig
>(
  'auth/loginWithAuthorizationCode',
  (body, { getState }) => {
    const state = getState();
    const config = selectAppConfig(state);
    const redirect_uri = new URL(state.router.location.pathname, window.location.href).href;
    return createToken(config, {
      code: state.router.location.query.code,
      redirect_uri,
      grant_type: 'authorization_code',
      client_id: config.client_id,
    });
  }
);

export type LoginByOAuthParams = {
  provider: 'google';
};
export const loginByOAuth = createAction('auth/loginByOAuth', (params: LoginByOAuthParams) => {
  let origin = window.location.origin;
  return {
    payload: {
      provider: params.provider,
      redirect_uri: origin + '/login/callback',
      state: generateRandomString(18),
    },
  };
});

async function getAuthHeaders(
  thunkAPI: ThunkAPI,
  options?: { refresh?: boolean },
): Promise<AuthHeaders> {
  let state = thunkAPI.getState();
  let { refresh = false } = options || {};
  if (refresh) {
    let action = await thunkAPI.dispatch(refreshAccessTokenAction());
    if (refreshAccessTokenAction.rejected.match(action)) {
      throw action.error;
    } else {
      return {
        Authorization: `${action.payload.token_type} ${action.payload.access_token}`,
      };
    }
  }
  return {
    Authorization: `${state.auth.token_type} ${state.auth.access_token}`,
  };
}

export type AuthApiRequestItf<RequestParams, ResponsePayload> = {
  (headers: AuthHeaders, params: RequestParams): Promise<ResponsePayload>;
}

export type BoundApiRequestItf<RequestParams, ResponsePayload> = {
  (params: RequestParams): Promise<ResponsePayload>;
}

export function bindApiWithAuthHeader<ResponsePayload, RequestParams = void>(
  apiRequest: AuthApiRequestItf<RequestParams, ResponsePayload>,
  thunkAPI: ThunkAPI,
): BoundApiRequestItf<RequestParams, ResponsePayload> {
  return async(params: RequestParams) => {
    try {
      const headers = await getAuthHeaders(thunkAPI);
      let res_data = await apiRequest(headers, params);
      return res_data;
    } catch(err) {
      let serialized_err = toSerializedError(err);
      if (serialized_err.code === '401') {
        const headers = await getAuthHeaders(thunkAPI, { refresh: true });
        let res_data = await apiRequest(headers, params);
        return res_data;
      }
      throw serialized_err;
    }
  };
}

export const getTokenInfoAction = createAsyncThunk<GetTokenInfoResponse, void, AppThunkConfig>(
  'auth/getTokenInfo',
  async (body, thunkAPI) => {
    let config = selectAppConfig(thunkAPI.getState());
    return bindApiWithAuthHeader((headers, params) => {
      return getTokenInfo({ ...config, headers });
    }, thunkAPI)(body);
  }
);

export const refreshAccessTokenAction = createAsyncThunk<LoginResponse, void, AppThunkConfig>(
  'auth/refresh',
  async (body, { getState }) => {
    let { client_id, auth_api_url } = selectAppConfig(getState());
    const refresh_token = getState().auth.refresh_token;
    if (!refresh_token) {
      let error: SerializedError = {
        code: '401',
        name: ErrorName.invalid_token,
      };
      throw error;
    }
    return createToken({ auth_api_url }, {
      grant_type: 'refresh_token',
      client_id,
      refresh_token,
    });
  }
);

export type AuthState = {
  token_type: string;
  access_token: string;
  refresh_token?: string;
  scope: string[];
  status: 'loading'|'loaded'|'checking'|'anonymous'|'logging_in'|'login'|'refreshing';
  is_initialized: boolean;
  oauth_state: string;
  error?: SerializedError;
};

const initialState: AuthState = {
  token_type: '',
  access_token: '',
  scope: [],
  is_initialized: false,
  oauth_state: '',
  status: 'loading',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutAction(state: AuthState) {
      state.access_token = '';
      state.refresh_token = undefined;
      state.scope = [];
      state.status = 'anonymous';
      state.is_initialized = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(REHYDRATE, (state, action: RehydrateAction) => {
        if (action.key === 'auth') {
          state.status = 'loaded';
        }
      })
      .addCase(loginWithPasswordAction.pending, (state, action) => {
        state.status = 'logging_in';
      })
      .addCase(loginWithPasswordAction.fulfilled, (state, action) => {
        if (state.status === 'logging_in') {
          state.status = 'login';
          state.token_type = action.payload.token_type;
          state.access_token = action.payload.access_token;
          state.refresh_token = action.payload.refresh_token;
          state.scope = action.payload.scope.split(' ');
        }
      })
      .addCase(loginWithPasswordAction.rejected, (state, action) => {
        if (state.status === 'logging_in') {
          state.status = 'anonymous';
          state.error = action.error;
        }
      })
      .addCase(loginByOAuth, (state, action) => {
        state.oauth_state = action.payload.state;
      })
      .addCase(loginWithAuthorizationCodeAction.pending, (state, action) => {
        state.status = 'logging_in';
      })
      .addCase(loginWithAuthorizationCodeAction.fulfilled, (state, action) => {
        if (state.status === 'logging_in') {
          state.status = 'login';
          state.token_type = action.payload.token_type;
          state.access_token = action.payload.access_token;
          state.refresh_token = action.payload.refresh_token;
          state.scope = action.payload.scope.split(' ');
          state.oauth_state = '';
          state.is_initialized = true;
        }
      })
      .addCase(loginWithAuthorizationCodeAction.rejected, (state, action) => {
        if (state.status === 'logging_in') {
          state.status = 'anonymous';
          state.error = action.error;
          state.is_initialized = true;
        }
      })
      .addCase(getTokenInfoAction.pending, (state, action) => {
        if (state.status === 'loaded') {
          state.status = 'checking';
        }
      })
      .addCase(getTokenInfoAction.fulfilled, (state, action) => {
        if (state.status === 'checking') {
          state.status = 'login';
          state.scope = action.payload.scope.split(' ');
          state.is_initialized = true;
        }
      })
      .addCase(getTokenInfoAction.rejected, (state, action) => {
        state.access_token = '';
        if (!state.refresh_token) {
          state.status = 'anonymous';
          state.is_initialized = true;
        }
        state.error = action.error;
      })
      .addCase(refreshAccessTokenAction.pending, (state, action) => {
        state.access_token = '';
        if (state.status !== 'checking') {
          state.status = 'refreshing';
        }
      })
      .addCase(refreshAccessTokenAction.fulfilled, (state, action) => {
        state.token_type = action.payload.token_type;
        state.access_token = action.payload.access_token;
        state.refresh_token = action.payload.refresh_token;
        state.scope = action.payload.scope.split(' ');
      })
      .addCase(refreshAccessTokenAction.rejected, (state, action) => {
        state.error = action.error;
        if (state.error?.name === ErrorName.invalid_grant) {
          state.refresh_token = undefined;
          state.status = 'anonymous';
          state.scope = [];
        }
      });
  },
});

export const { logoutAction } = authSlice.actions;
export const authPersistConfig: Omit<PersistConfig<AuthState>, 'storage'> = {
  key: 'auth',
  version: 1,
  whitelist: ['token_type', 'access_token', 'refresh_token', 'oauth_state'],
};

export const authMiddleware: Middleware = (
  { dispatch, getState }: MiddlewareAPI<AppDispatch, RootState>
) => {
  return (next: Dispatch) => {
    return (action: AnyAction) => {
      let result = next(action);
      if (refreshAccessTokenAction.rejected.match(action)) {
        dispatch(logoutAction());
      } else if (logoutAction.match(action)) {
        dispatch(push(route_path.login));
      } else if (loginByOAuth.match(action)) {
        let config = selectAppConfig(getState());
        const url = new URL(config.auth_api_url + '/api/oauth/authorize');
        url.searchParams.append('client_id', config.client_id);
        url.searchParams.append('redirect_uri', action.payload.redirect_uri);
        url.searchParams.append('response_type', 'code');
        url.searchParams.append('provider', action.payload.provider);
        url.searchParams.append('state', action.payload.state)
        window.location.href = url.toString();
      }
      return result;
    }
  };
};

export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectHasAccessToken = (state: RootState) => state.auth.access_token !== '';
export const selectIsInitialized = (state: RootState) => state.auth.is_initialized;
export const selectIsOauthLoginCallback = (state: RootState) => {
  return state.router.location.query.code && state.router.location.query.state === state.auth.oauth_state;
};

export const hasScope = (state: RootState, scope: ALL_SCOPES) => {
  return state.auth.scope.includes(scope);
}
