import { SerializedError } from '@reduxjs/toolkit';
import axios from 'axios';

import { ErrorName } from './error-name';

function isSerialiedError(err: unknown): err is SerializedError {
  return typeof err === 'object';
}

export function toSerializedError(err: unknown): SerializedError {
  if (axios.isAxiosError(err) && err.response) {
    return {
      name: err.response.data.error,
      message: err.response.data.error_description,
      code: ''+err.response.status,
      stack: err.response.data.error_detail,
    };
  }
  if (err instanceof Error) {
    return {
      name: ErrorName.unknown,
      message: err.message,
      code: '500',
      stack: err.stack,
    }
  }
  if (isSerialiedError(err)) {
    return {
      name: err.name ?? ErrorName.unknown,
      message: err.message,
      code: err.code,
      stack: err.stack,
    };
  }
  return {
    name: ErrorName.unknown,
    code: '500',
  }
}
