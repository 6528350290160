import {UseFormRegisterReturn} from 'react-hook-form';

import {ValueItem} from './ValueItem';

type Props = {
  title: string;
  name: string;
  mode: 'read'|'write';
  value?: string;
  options?: { title: string; value: string; }[];
  errorMsg?: string;
  regForm?: () => UseFormRegisterReturn;
};

export function SelectItem({ title, mode, name, errorMsg, value, options = [], regForm }: Props) {
  if (mode === 'write') {
    return (
      <div className="row py-1">
        <label htmlFor={name} className="col-5 col-form-label">{title}</label>
        <div className="col-7">
          <select id={name} className="form-select"
            { ...regForm?.() }
          >
            <option value="">- 請選擇 -</option>
            {options.map((option, index) => (
              <option value={option.value} key={index}>{option.title}</option>
            ))}
          </select>
        </div>
        <span className="text-danger py-1">{errorMsg}</span>
      </div>
    );
  }
  return (
    <ValueItem title={title} name={name} value={value}/>
  );
}
