import React from 'react';
import { useParams } from 'react-router';
import { FaUserPlus } from 'react-icons/fa';
import { useForm } from 'react-hook-form';

import { SchoolModal } from './components/SchoolModal';
import { SchoolTeacherRow } from './components/SchoolTeacherRow';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  createSchoolTeacherAction,
  listSchoolTeachersAction,
  selectSchoolInfo,
  selectSchoolTeachers,
} from './schoolSlice';
import { TeacherDeleteModal } from './components/TeacherDeleteModal';
import { StudentModal } from './components/StudentModal';

type FormData = {
  user_email: string;
};

const initialForm: FormData = {
  user_email: '',
};

export default function TeacherListPage() {
  const dispatch = useAppDispatch();
  const params = useParams<{ school_id: string; }>();

  const teachers = useAppSelector(selectSchoolTeachers);
  const school = useAppSelector((state) => selectSchoolInfo(state, params.school_id));
  const { handleSubmit, register, reset, watch, formState: { errors } } = useForm({
    defaultValues: initialForm,
  });
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    dispatch(listSchoolTeachersAction({ id: params.school_id }));
  }, [params.school_id]);

  React.useEffect(() => {
    if (errMsg) {
      setErrMsg('');
    }
  }, [watch('user_email')]);

  const onAdd = async(body: FormData) => {
    let result = await dispatch(createSchoolTeacherAction({
      id: params.school_id,
      user_email: body.user_email,
    }));
    if (createSchoolTeacherAction.fulfilled.match(result)) {
      reset(initialForm);
      dispatch(listSchoolTeachersAction({ id: params.school_id }));
    } else {
      setErrMsg(result.error.message ?? result.error.name ?? 'Unknown');
    }
  };

  return (
    <div className="container kd-main">
      <div className="d-flex justify-content-between my-3">
        <h2 className="w-100">{school?.name} 老師列表</h2>
        <form className="input-group flex-shrink-1" onSubmit={handleSubmit(onAdd)}>
          <label htmlFor="add-teacher-byemail" className="input-group-text fw-bold">加入名單</label>
          <input type="text" id="add-teacher-byemail" placeholder="輸入老師Email" className="form-control" {...register('user_email')}/>
          <button className="btn btn-primary" type="submit">
            <FaUserPlus className="text-light" /> 新增
          </button>
        </form>
      <span className="text-danger">{errMsg ?? errors.user_email?.message}</span>
      </div>
      <table className="table table-hover">
        <thead className="table-secondary">
          <tr>
            <th scope="col">#</th>
            <th scope="col">名稱</th>
            <th scope="col">信箱</th>
            <th scope="col">狀態</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {teachers.map((teacher, index) => 
          <SchoolTeacherRow key={index} schoolId={params.school_id} item={teacher} index={index} />
          )}
        </tbody>
      </table>
      <SchoolModal/>
      <TeacherDeleteModal/>
      <StudentModal/>
    </div>
  );
}
