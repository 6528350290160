import React from "react";
import { useAppDispatch } from "app/hooks";
import { SelectItem } from "components/input/SelectItem";
import { TextItem } from "components/input/TextItem";
import { CreateGiftCardBatchBody } from "handlers/keedle";
import { useForm } from "react-hook-form";
import { EntityRegisterOptions } from "types";
import { createGiftCardBatchAction, productOptions } from "../giftCardSlice";
import { useHistory } from "react-router";
import { route_path } from "app/route";

type FormData = CreateGiftCardBatchBody;

const fieldOptions: EntityRegisterOptions<FormData> = {
  name: {
    required: '必填',
  },
  productId: {
    required: '必填',
  },
  qty: {
    required: '必填',
    min: {
      value: 1,
      message: '至少大於1'
    },
    max: {
      value: 10000,
      message: '不能大於10000'
    },
  },
  unit_qty: {
    required: '必填',
    min: {
      value: 1,
      message: '至少大於1'
    },
    max: {
      value: 1000,
      message: '不能大於1000'
    },
  },
  expired_ts: {
    required: '必填',
    pattern: {
      value: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
      message: '格式必需是 YYYY-MM-DD',
    },
  },
};

const initialForm: FormData = {
  name: '',
  productId: '',
  qty: 1,
  unit_qty: 1,
  description: undefined,
  expired_ts: undefined,
};

export default function CreateGiftCardBatchPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [errMsg, setErrMsg] = React.useState('');

  const { handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: initialForm,
  });

  const onSubmit = async (newData: FormData) => {
    const expired_ts = +(new Date(newData.expired_ts ?? 0)) + 24*60*60*1000 - 1;
    let result = await dispatch(createGiftCardBatchAction({
      ...newData,
      description: newData.description || undefined,
      expired_ts,
    }));
    if (createGiftCardBatchAction.fulfilled.match(result)) {
      history.push(route_path.giftCardBatch);
    } else {
      setErrMsg(result.error.message ?? 'unknown');
    }
  };

  return (
    <div className="container kd-main">
      <h2 className="my-2">新增禮物卡</h2>
      <form className="container-lg" onSubmit={handleSubmit(onSubmit)}>
        <TextItem
          title="批次名稱"
          name="name"
          mode="write"
          placeholder="代表這個批次的任何名稱"
          regForm={() => register('name', fieldOptions.name)}
          errorMsg={errors.name?.message}
        />
        <SelectItem
          title="可兌換產品"
          name="productId"
          mode="write"
          options={productOptions}
          regForm={() => register('productId', fieldOptions.productId)}
          errorMsg={errors.productId?.message}
        />
        <TextItem
          title="每張面額(單位：點)"
          name="unit_qty"
          mode="write"
          type="number"
          placeholder="輸入數字"
          regForm={() => register('unit_qty', fieldOptions.unit_qty)}
          errorMsg={errors.unit_qty?.message}
        />
        <TextItem
          title="發行數量(單位：張)"
          name="qty"
          mode="write"
          type="number"
          placeholder="輸入數字"
          regForm={() => register('qty', fieldOptions.qty)}
          errorMsg={errors.qty?.message}
        />
        <TextItem
          title="序號效期"
          name="expired_ts"
          mode="write"
          type="text"
          placeholder="日期格式：YYYY-MM-DD"
          regForm={() => register('expired_ts', fieldOptions.expired_ts)}
          errorMsg={errors.expired_ts?.message}
        />
        <TextItem
          title="備註(選填)"
          name="description"
          mode="write"
          type="text"
          regForm={() => register('description', fieldOptions.description)}
          errorMsg={errors.description?.message}
        />
        {errMsg &&
          <span className="text-danger">{errMsg}</span>
        }
        <div className="d-flex justify-content-center mt-3">
          <button type="submit" className="btn btn-primary">確認新增</button>
        </div>
      </form>
    </div>
  );
}
