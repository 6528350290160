import { FaRegTrashAlt } from 'react-icons/fa';

import { SchoolMember } from 'types';
import { useAppDispatch } from 'app/hooks';
import { deleteSchoolAdminAction, listSchoolAdminsAction } from '../schoolSlice';

type Props = {
  schoolId: string;
  item: SchoolMember;
  index: number;
};

const UserStateMap = {
  enabled: '啟用',
  disabled: '停用',
  removed: '已移除',
  inviting: '邀請中',
};
export function SchoolAdminRow({ schoolId, item, index }: Props) {
  const dispatch = useAppDispatch();
  const onDelete = async () => {
    let result = await dispatch(deleteSchoolAdminAction({
      id: schoolId,
      ...item,
    }));
    if (deleteSchoolAdminAction.fulfilled.match(result)) {
      dispatch(listSchoolAdminsAction({ id: schoolId }));
    }
  }

  if ('role_binding_id' in item) {
    return (
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{item.user_name}</td>
        <td>{item.user_email}</td>
        <td>{UserStateMap[item.user_state]}</td>
        <td>
          <button className="btn btn-outline-danger btn-sm" onClick={onDelete}>
            <FaRegTrashAlt className="mb-1" /> 移除管理員
          </button>
        </td>
      </tr>
    );
  }
  return (
    <tr>
      <th scope="row">{index + 1}</th>
      <td></td>
      <td>{item.user_email}</td>
      <td>{UserStateMap[item.user_state]}</td>
      <td>
        <button className="btn btn-outline-danger btn-sm" onClick={onDelete}>
          <FaRegTrashAlt className="mb-1" /> 移除管理員
        </button>
      </td>
    </tr>
  );
}
