import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { useAppDispatch, useAppSelector } from 'app/hooks';

import {
  closeStudentModalAction,
  listTeacherStudentsAction,
  selectListStudentModal,
  selectOpenedStudentModalTeacherInfo,
} from '../schoolSlice';

export function StudentModal() {
  const dispatch = useAppDispatch();

  const modal = useAppSelector(selectListStudentModal);
  const teacher = useAppSelector(selectOpenedStudentModalTeacherInfo);

  React.useEffect(() => {
    if (modal.schoolId && modal.visible) {
      dispatch(listTeacherStudentsAction({
        school_id: modal.schoolId,
        teacher_id: modal.teacherId,
      }))
    }
  }, [modal.visible, modal.schoolId, modal.teacherId]);

  const onHide = () => {
    dispatch(closeStudentModalAction());
  };

  return (
    <Modal show={modal.visible} onHide={onHide} size="lg">
      <Modal.Header className="bg-info text-light">
        <h5>老師: {teacher?.user_name}</h5>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead className="table-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">名稱</th>
              <th scope="col">AAIS 幼兒版 已使用點數</th>
              <th scope="col">AAIS 學齡版 已使用點數</th>
              <th scope="col">AAIS 組合版 已使用點數</th>
              <th scope="col">EBAS 幼兒版 已使用點數</th>
              <th scope="col">EBAS 學齡版 已使用點數</th>
              <th scope="col">EBAS 組合版 已使用點數</th>
            </tr>
          </thead>
          <tbody>
            {modal.students.map((student, index) =>
              <tr>
                <th scope="row">{index + 1}</th>
                <td>{student.name}</td>
                <td>{student.quota_usage.AAIS_3TO6_SCHOOL}</td>
                <td>{student.quota_usage.AAIS_OVER6_SCHOOL}</td>
                <td>{student.quota_usage.AAIS_SCHOOL}</td>
                <td>{student.quota_usage.EBAS_3TO6_SCHOOL}</td>
                <td>{student.quota_usage.EBAS_OVER6_SCHOOL}</td>
                <td>{student.quota_usage.EBAS_SCHOOL}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
      </Modal.Footer>
    </Modal>
  );
}

