import { MouseEvent } from 'react';
import { FaEllipsisH } from 'react-icons/fa';

type Props = {
  pageNumber: number;
  isActive?: boolean;
  onClick?: () => void;
};

export function PageItem(props: Props) {
  if (props.pageNumber === -1) {
    return (
      <li className="page-item d-none d-sm-block">
        <span className="page-link">
          <FaEllipsisH />
        </span>
      </li>
    );
  }

  if (props.isActive) {
    return (
      <li className="page-item active" aria-current="page">
        <span className="page-link">{props.pageNumber}</span>
      </li>
    );
  }

  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    props.onClick?.();
  };

  return (
    <li className="page-item" onClick={props.onClick}>
      <button className="page-link" onClick={onClick}>
        {props.pageNumber}
      </button>
    </li>
  );
};
