import { PageItem } from './PageItem';
import { getPageNumberList } from '../../utils/page.util';

type Props = {
  pageNumber: number;
  prePage: number;
  totalCount: number;
  onClick?: (page: number) => void;
}

export function PageButton(props: Props) {
  let pages = getPageNumberList({
    page: props.pageNumber,
    pre_page: props.prePage,
    total_count: props.totalCount,
  });
  return (
    <ul className="pagination justify-content-center">
      {pages.map((page_num, index) => {
        let isActive = page_num === (props.pageNumber);
        return (
          <PageItem
            key={index} pageNumber={page_num} isActive={isActive}
            onClick={() => props.onClick?.(page_num)}
          />
        );
      })}
    </ul>
  );
}
