import axios from 'axios';

import { ApiConfig } from '../types';
import { toSerializedError } from '../errors/utils';

export type AuthorizationCodeBody = {
  grant_type: 'authorization_code';
  code: string;
  redirect_uri: string;
  client_id: string;
};

export type PasswordLoginBody = {
  grant_type: 'password';
  username: string;
  password: string;
  client_id: string;
  scope?: string;
};

export type RefreshTokenBody = {
  grant_type: 'refresh_token';
  refresh_token: string;
  client_id: string;
};

export type LoginResponse = {
  access_token: string;
  token_type: 'Bearer';
  refresh_token?: string;
  expires_in: number;
  scope: string;
};

export async function createToken({ auth_api_url }: { auth_api_url: string }, body: PasswordLoginBody|RefreshTokenBody|AuthorizationCodeBody) {
  try {
    let res = await axios.post<LoginResponse>(auth_api_url+'/api/oauth/token', body);
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type GetTokenInfoResponse = {
  tenant_id: string;
  scope: string;
};

export async function getTokenInfo({ auth_api_url, headers }: ApiConfig) {
  try {
    let res = await axios.get<GetTokenInfoResponse>(auth_api_url+'/api/oauth/token', {
      headers,
    });
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}
