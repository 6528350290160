import {UseFormRegisterReturn} from 'react-hook-form';

type Props = {
  title: string;
  name: string;
  mode: 'read'|'write';
  value?: boolean;
  placeholder?: string;
  errorMsg?: string;
  regForm?: () => UseFormRegisterReturn;
};

export function CheckboxItem({ title, mode, name, placeholder, errorMsg, value, regForm }: Props) {
  return (
    <div className="row py-1">
      <label htmlFor={name} className="col-sm-3 col-form-label">{title}</label>
      <div className="col-sm-6 d-flex align-items-center">
        <div className="form-check">
          {mode === 'read' ?
          <input readOnly type="checkbox" id={name} className="form-check-input" checked={value}/>
          :
          <input type="checkbox" id={name} className="form-check-input"
            { ...regForm?.() }
          />
          }
        </div>
      </div>
      <span className="col-sm-3 text-danger py-1">{errorMsg}</span>
    </div>
  );
}
