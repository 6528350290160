export type Page = {
  page: number;
  pre_page: number;
  total_count: number;
};

export const getPageNumberList = ({ page, pre_page, total_count }: Page): number[] => {
  let max_page_num = Math.ceil(total_count/pre_page);
  let page_list: number[] = [];
  if (max_page_num > 7) {
    if ([1, 2, max_page_num-1, max_page_num].includes(page)) {
      if (page <= 2) {
        page_list = [1, 2, 3, 4, 5, -1, max_page_num];
      } else {
        page_list = [1, -1, max_page_num-4, max_page_num-3,
          max_page_num-2, max_page_num-1, max_page_num];
      }
    } else {
      page_list = [1, -1, page-1, page, page+1, -1, max_page_num];
    }
  } else {
    for (let i=0; i < max_page_num; i++) {
      page_list.push(i + 1);
    }
  }
  return page_list;
};
