import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  getAppConfigAction,
  selectConfigStatus,
} from './configSlice';

type Props = {
  children: React.ReactNode;
};

export function ConfigProvider(props: Props) {
  const dispatch = useAppDispatch();
  const config_status = useAppSelector(selectConfigStatus);
  useEffect(() => {
    dispatch(getAppConfigAction());
  }, [dispatch]);
  if (config_status !== 'success') {
    return (
      <div>Loading...</div>
    );
  }
  return (
    <>
      {props.children}
    </>
  );
}
