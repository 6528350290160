import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { AppConfig } from '../../types';
import {
  getAppConfig,
} from '../../handlers';


export type ConfigState = AppConfig & {
  status: 'idle'|'loading'|'failed'|'success';
  error?: SerializedError;
};

const initialState: ConfigState = {
  api_url: '',
  auth_api_url: '',
  invite_code_uri: '',
  client_id: '',
  status: 'idle',
};

export const getAppConfigAction = createAsyncThunk(
  'config/get',
  async() => {
    return getAppConfig();
  }
);

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppConfigAction.pending, (state, action) => {
        state.status = 'idle';
      })
      .addCase(getAppConfigAction.fulfilled, (state, action) => {
        state.status = 'success';
        state.api_url = action.payload.api_url;
        state.auth_api_url = action.payload.auth_api_url;
        state.invite_code_uri = action.payload.invite_code_uri;
        state.client_id = action.payload.client_id;
      })
      .addCase(getAppConfigAction.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
  },
});

export const selectAppConfig = (state: RootState) => {
  return {
    api_url: state.config.api_url,
    auth_api_url: state.config.auth_api_url,
    client_id: state.config.client_id,
  };
};
export const selectConfigStatus = (state: RootState) => state.config.status;
