type Props = {
  title: string;
  name: string;
  value?: string;
};

export function ValueItem({name, title, value}: Props) {
  return (
    <div className="row py-1">
      <label htmlFor={name} className="col-sm-3 col-form-label">{title}</label>
      <div className="col-sm-6">
        <span id={name} className="form-control-plaintext px-3">{value}</span>
      </div>
    </div>
  );
}
