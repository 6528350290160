import React from 'react';
import { CSVLink } from 'react-csv';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  disableGiftCardBatchAction,
  displayDate,
  displayDateTime,
  displayProductName,
  getGiftCardBatchAction,
  listGiftCardAction,
  selectGetBatchInfo,
  selectListGiftCards,
} from '../giftCardSlice';
import { useParams } from 'react-router-dom';
import { selectCanWriteGiftCard } from 'features/user/userSlice';
import { GiftCard } from 'types';

function displayState(state: GiftCard['state']) {
  if (state === 'enabled') {
    return '未使用';
  } else if (state === 'disabled') {
    return '已作廢';
  } else if (state === 'exchanged') {
    return '已使用';
  } else if (state === 'expired') {
    return '過期失效';
  }
  return state;
}

function convert2CsvData(data: GiftCard[]) {
  return data.map(row => {
    return {
      ...row,
      state_text: displayState(row.state),
    };
  });
}

const giftCardHeaders = [
  { label: '序號', key: 'no' },
  { label: '狀態', key: 'state_text' },
  { label: '使用人', key: 'exchanged_user_email' },
];

export default function GiftCardListPage() {
  const dispatch = useAppDispatch();
  const params = useParams<{ batch_id: string; }>();
  const [errMsg, setErrMsg] = React.useState('');

  const canWrite = useAppSelector(selectCanWriteGiftCard);
  const giftCards = useAppSelector(selectListGiftCards);
  const batch = useAppSelector((state) => selectGetBatchInfo(state, params.batch_id));

  React.useEffect(() => {
    dispatch(getGiftCardBatchAction({ id: params.batch_id }));
    dispatch(listGiftCardAction({ batch_id: params.batch_id }));
  }, [params.batch_id]);

  const onClickDisable = async (no?: string) => {
    let result;
    if (no) {
      result = await dispatch(disableGiftCardBatchAction({ no }));
    } else {
      result = await dispatch(disableGiftCardBatchAction({ batch_id: params.batch_id }));
    }
    if (disableGiftCardBatchAction.fulfilled.match(result)) {
      dispatch(listGiftCardAction({ batch_id: params.batch_id }));
    } else {
      setErrMsg(result.error.message ?? 'unknown');
    }
  }

  return (
    <div className="container kd-main mb-4">
      <h2 className="my-2">禮物卡批次資訊</h2>
      <div className="table-responsive">
        <table className="table">
          <thead className="table table-success">
            <tr>
              <th scope="col">批次名稱</th>
              <th scope="col">產品名稱</th>
              <th scope="col">面額</th>
              <th scope="col">發行數量</th>
              <th scope="col">已使用數量</th>
              <th scope="col">序號效期</th>
              <th scope="col">建立日期</th>
              <th scope="col">兌換密碼</th>
              <th scope="col">備註</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{batch?.name}</td>
              <td>{displayProductName(batch?.productId ?? '')}</td>
              <td>{batch?.unit_qty}</td>
              <td>{batch?.qty}</td>
              <td>{batch?.exchanged_qty}</td>
              <td>{displayDate(batch?.expired_ts)}</td>
              <td>{displayDate(batch?.created_ts)}</td>
              <td className="text-info">{batch?.exchanged_password}</td>
              <td className="text-secondary">{batch?.description}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="my-2 d-flex justify-content-between align-items-center">
        <h2 className="py-2">序號列表</h2>
        <CSVLink className="btn btn-primary"
          filename={batch?.name+'.csv'}
          data={convert2CsvData(giftCards)} headers={giftCardHeaders}>
          下載全部序號(.csv)
        </CSVLink>
      </div>
      {errMsg &&
        <span className="text-danger">{errMsg}</span>
      }
      <div className="table-responsive">
        <table className="table table-hover">
          <thead className="table table-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">序號</th>
              <th scope="col">狀態</th>
              <th scope="col">使用人</th>
              <th scope="col">最後更新時間</th>
              <th scope="col">操作</th>
            </tr>
          </thead>
          <tbody>
            {giftCards.map((giftCard, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{giftCard.no}</td>
                  <td>{displayState(giftCard.state)}</td>
                  <td>{giftCard.exchanged_user_email}</td>
                  <td>{displayDateTime(giftCard.updated_ts)}</td>
                  <td>
                    {canWrite && giftCard.state === 'enabled' &&
                      <button type="button" className="btn btn-link link-danger" onClick={() => onClickDisable(giftCard.no)}>
                        作廢這組序號
                      </button>
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {canWrite &&
      <div className="d-flex justify-content-end">
        <button className="btn btn-danger" onClick={() => onClickDisable()}>
          整批作廢(已使用的序號除外)
        </button>
      </div>
      }
    </div>
  );
}

