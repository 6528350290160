import { configureStore, ThunkAction, Action, applyMiddleware, } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';

import { configSlice } from 'features/config/configSlice';
import { authSlice, AuthState, authMiddleware, authPersistConfig } from 'features/auth/authSlice';
import { parentSlice } from 'features/parents/parentSlice';
import { schoolSlice } from 'features/schools/schoolSlice';
import { userSlice } from 'features/user/userSlice';
import { giftCardSlice } from 'features/gift-card/giftCardSlice';

export function configure(history: History) {
  const routeReducer = connectRouter(history);
  const routeEnhancer = applyMiddleware(routerMiddleware(history));
  const store = configureStore({
    reducer: {
      config: configSlice.reducer,
      auth: persistReducer<AuthState>({ ...authPersistConfig, storage }, authSlice.reducer),
      parent: parentSlice.reducer,
      school: schoolSlice.reducer,
      user: userSlice.reducer,
      router: routeReducer,
      giftCard: giftCardSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(authMiddleware);
    },
    enhancers: [routeEnhancer],
  });
  const persistor = persistStore(store)
  return { store, persistor };
}


export type store = ReturnType<typeof configure>['store'];
export type AppDispatch = store['dispatch'];
export type RootState = ReturnType<store['getState']>;
export type ThunkAPI = {
  dispatch: AppDispatch;
  getState: () => RootState;
};
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppThunkConfig = {
  dispatch: AppDispatch;
  state: RootState;
};
