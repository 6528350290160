import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { FaUserPlus } from 'react-icons/fa';
import { MdAdminPanelSettings } from 'react-icons/md';

import { SchoolAdminRow } from './SchoolAdminRow';

import { useAppDispatch, useAppSelector } from 'app/hooks';

import {
  closeSchoolAdminModalAction,
  createSchoolAdminAction,
  listSchoolAdminsAction,
  selectSchoolAdminModal,
  selectSchoolInfo,
} from '../schoolSlice';

type FormData = {
  user_email: string;
};

const initialForm: FormData = {
  user_email: '',
};

export function SchoolAdminModal() {
  const dispatch = useAppDispatch();

  const modal = useAppSelector(selectSchoolAdminModal);
  const school = useAppSelector((state) => selectSchoolInfo(state, modal.schoolId));
  const { handleSubmit, register, reset, watch, formState: { errors } } = useForm({
    defaultValues: initialForm,
  });
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    if (modal.schoolId && modal.visible) {
      dispatch(listSchoolAdminsAction({ id: modal.schoolId }))
        .then((result) => {
          if (listSchoolAdminsAction.rejected.match(result)) {
            setErrMsg(result.error.message ?? result.error.name ?? 'Unknown');
          }
        });
      return () => {
        reset(initialForm);
        setErrMsg('');
      };
    }
  }, [modal.visible, modal.schoolId]);

  React.useEffect(() => {
    if (errMsg) {
      setErrMsg('');
    }
  }, [watch('user_email')]);

  const onAdd = async(body: FormData) => {
    let result = await dispatch(createSchoolAdminAction({
      id: modal.schoolId,
      user_email: body.user_email,
    }));
    if (createSchoolAdminAction.fulfilled.match(result)) {
      reset(initialForm);
      dispatch(listSchoolAdminsAction({ id: modal.schoolId }));
    } else {
      setErrMsg(result.error.message ?? result.error.name ?? 'Unknown');
    }
  };

  const onHide = () => {
    dispatch(closeSchoolAdminModalAction());
  };

  return (
    <Modal show={modal.visible} onHide={onHide} size="lg">
      <Modal.Header className="bg-secondary text-light">
        <h4 className="mt-1 mb-0"><MdAdminPanelSettings className="mb-1" /> {school?.name} 的管理員</h4>
      </Modal.Header>
      <Modal.Body>
        <form className="input-group mt-2 mb-4" onSubmit={handleSubmit(onAdd)}>
          <span className="input-group-text fw-bold" id="add-schooladmin-byemail">新增管理員</span>
          <input type="text" id="add-schooladmin-byemail" placeholder="輸入管理者Email" className="form-control" {...register('user_email')}/>
          <button className="btn btn-primary" type="submit">
            <FaUserPlus className="text-light" /> 新增
          </button>
        </form>
        <span className="text-danger">{errMsg ?? errors.user_email?.message}</span>
        <table className="table table-hover">
          <thead className="table-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">名稱</th>
              <th scope="col">信箱</th>
              <th scope="col">狀態</th>
              <th scope="col">操作</th>
            </tr>
          </thead>
          <tbody>
            {modal.data.map((schoolAdmin, index) =>
              <SchoolAdminRow key={index} schoolId={modal.schoolId} item={schoolAdmin} index={index} />
            )}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
      </Modal.Footer>
    </Modal>
  );
}
