import React, { useEffect } from 'react';
import { useHistory, matchPath } from 'react-router';

import { route_path } from '../../app/route';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  selectAuthStatus,
  selectHasAccessToken,
  selectIsInitialized,
  selectIsOauthLoginCallback,
  loginWithAuthorizationCodeAction,
  getTokenInfoAction,
  logoutAction,
} from './authSlice';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider(props: Props) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const auth_status = useAppSelector(selectAuthStatus);
  const has_access_token = useAppSelector(selectHasAccessToken);
  const is_initialized = useAppSelector(selectIsInitialized);
  const is_oauth_login_cb = useAppSelector(selectIsOauthLoginCallback);

  useEffect(() => {
    if (auth_status === 'loaded') {
      if (has_access_token) {
        dispatch(getTokenInfoAction());
      } else if (is_oauth_login_cb) {
        dispatch(loginWithAuthorizationCodeAction())
      } else {
        dispatch(logoutAction());
      }
    } else if (auth_status === 'login') {
      const is_match = matchPath(history.location.pathname, {
        path: route_path.login,
        exact: false
      });
      if (is_match) {
        history.push(route_path.home);
      }
    }
  }, [auth_status, has_access_token, is_oauth_login_cb, dispatch, history]);

  // login with authorization_code
  if (!is_initialized) {
    return (
      <div>Loading</div>
    );
  }
  return (
    <>
      {props.children}
    </>
  );
}
