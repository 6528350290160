import React from 'react';
import { MdInfoOutline } from 'react-icons/md';

import { PageButton } from 'components/page/PageButton';
import { ParentQuotaModal } from './components/ParentQuotaModal';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  listParentsAction,
  setListPageAction,
  selectListParents,
  selectListParentsPage,
  openParentModalAction,
  selectParentModal,
} from './parentSlice';

export default function ParentListPage() {
  const dispatch = useAppDispatch();

  const page = useAppSelector(selectListParentsPage);
  const parents = useAppSelector(selectListParents);
  const modal = useAppSelector(selectParentModal);

  React.useEffect(() => {
    dispatch(listParentsAction({ ...page, sort_by: [] }));
  }, [page]);

  React.useEffect(() => {
    if (modal.visible) {
      return () => {
        dispatch(listParentsAction({ ...page, sort_by: [] }));
      }
    }
  }, [modal.visible, page]);

  const onChangePage = (pageNumber: number) => {
    dispatch(setListPageAction({ page: pageNumber }));
  };

  const onOpenParentModal = (parent_id: string) => {
    dispatch(openParentModalAction(parent_id));
  };
  return (
    <div className="container kd-main mt-3">
      <h2 className="mb-3">家長列表</h2>
      <table className="table">
        <thead className="table-primary">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile</th>
            <th scope="col">State</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {parents.map((parent, index) => {
            return (
              <tr key={index}>
                <th scope="row">{ index+1 }</th>
                <td>{parent.name}</td>
                <td>{parent.email}</td>
                <td>{parent.mobile}</td>
                <td>{parent.state}</td>
                <td>
                  <button className="btn btn-outline-primary" onClick={() => onOpenParentModal(parent.user_id)}>
                    <MdInfoOutline className="mb-1" /> 資訊與點數
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="row d-flex">
        <PageButton
          pageNumber={page.page}
          prePage={page.pre_page}
          totalCount={page.total_count}
          onClick={onChangePage}
        />
      </div>
      <ParentQuotaModal/>
    </div>
  );
}
