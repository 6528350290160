import {
  bindApiWithAuthHeader,
  BoundApiRequestItf,
} from '../features/auth/authSlice';
import {
  selectAppConfig,
} from '../features/config/configSlice';
import { ThunkAPI } from '../app/store';
import { ApiConfig, AuthHeaders } from '../types';

export type ApiConfigRequestItf<RequestParams, ResponsePayload> = {
  (config: ApiConfig, params: RequestParams): Promise<ResponsePayload>;
}

export function bindAPI<ResponsePayload, RequestParams = void>(
  api: ApiConfigRequestItf<RequestParams, ResponsePayload>,
  thunkAPI: ThunkAPI,
): BoundApiRequestItf<RequestParams, ResponsePayload> {
  let config = selectAppConfig(thunkAPI.getState());
  return bindApiWithAuthHeader((headers: AuthHeaders, params: RequestParams) => {
    return api({ ...config, headers }, params);
  }, thunkAPI);
}
