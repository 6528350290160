import { SerializedError } from '@reduxjs/toolkit';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import { ErrorName } from '../errors/error-name';

export function stripEmptyString<T extends object>(input: T, options?: { convert_to_null: boolean }): T {
  let convert_to = options?.convert_to_null ? null : undefined;
  if (isPlainObject(input)) {
    return mapValues(input, (value) => {
      return value as unknown === '' ? convert_to : value;
    }) as T;
  }
  const error: SerializedError = {
    name: ErrorName.unknown,
    message: 'Invalid input',
  };
  throw error;
}
