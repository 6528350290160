import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState, AppThunkConfig } from 'app/store';
import { bindAPI } from 'app/auth'
import {
  ListSchoolsResponse,
  listSchools,
  CreateSchoolParams,
  CreateSchoolResponse,
  createSchool,
  GetSchoolParams,
  GetSchoolResponse,
  getSchool,
  UpdateSchoolParams,
  UpdateSchoolResponse,
  updateSchool,
  DeleteSchoolParams,
  DeleteSchoolResponse,
  deleteSchool,
  ListSchoolAdminParams,
  ListSchoolAdminsResponse,
  listSchoolAdmins,
  CreateSchoolAdminParams,
  CreateSchoolAdminResponse,
  createSchoolAdmin,
  DeleteSchoolAdminParams,
  DeleteSchoolAdminResponse,
  deleteSchoolAdmin,
  ConfirmSchoolInvitingResponse,
  confirmSchoolInviting,
  ListSchoolTeacherParams,
  ListSchoolTeachersResponse,
  listSchoolTeachers,
  CreateSchoolTeacherParams,
  CreateSchoolTeacherResponse,
  createSchoolTeacher,
  DeleteSchoolTeacherParams,
  DeleteSchoolTeacherResponse,
  deleteSchoolTeacher,
  listTeacherStudents,
  ListTeacherStudentsParams,
  ListTeacherStudentsResponse,
} from 'handlers';
import { School, SchoolMember, SchoolDetail, Student, InvitedSchoolMember } from 'types';
import { logoutAction } from 'features/auth/authSlice';
import { selectCanReadSchoolInfo } from 'features/user/userSlice';

export const listSchoolsAction = createAsyncThunk<
  ListSchoolsResponse, void, AppThunkConfig
>(
  'school/List',
  (arg, thunkAPI) => bindAPI(listSchools, thunkAPI)(arg),
);

export const getSchoolAction = createAsyncThunk<
  GetSchoolResponse, GetSchoolParams, AppThunkConfig
>(
  'school/Get',
  (arg, thunkAPI) => bindAPI(getSchool, thunkAPI)(arg),
);

export const createSchoolAction = createAsyncThunk<
  CreateSchoolResponse, CreateSchoolParams, AppThunkConfig
>(
  'school/Create',
  (arg, thunkAPI) => bindAPI(createSchool, thunkAPI)(arg),
);

export const updateSchoolAction = createAsyncThunk<
  UpdateSchoolResponse, UpdateSchoolParams, AppThunkConfig
>(
  'school/Update',
  (arg, thunkAPI) => bindAPI(updateSchool, thunkAPI)(arg),
);

export const deleteSchoolAction = createAsyncThunk<
  DeleteSchoolResponse, DeleteSchoolParams, AppThunkConfig
>(
  'school/Delete',
  (arg, thunkAPI) => bindAPI(deleteSchool, thunkAPI)(arg),
);

export const listSchoolAdminsAction = createAsyncThunk<
  ListSchoolAdminsResponse, ListSchoolAdminParams, AppThunkConfig
>(
  'schoolAdmin/List',
  (arg, thunkAPI) => bindAPI(listSchoolAdmins, thunkAPI)(arg),
);

export const createSchoolAdminAction = createAsyncThunk<
  CreateSchoolAdminResponse, CreateSchoolAdminParams, AppThunkConfig
>(
  'schoolAdmin/Create',
  (arg, thunkAPI) => bindAPI(createSchoolAdmin, thunkAPI)(arg),
);

export const deleteSchoolAdminAction = createAsyncThunk<
  DeleteSchoolAdminResponse, DeleteSchoolAdminParams, AppThunkConfig
>(
  'schoolAdmin/Delete',
  (arg, thunkAPI) => bindAPI(deleteSchoolAdmin, thunkAPI)(arg),
);

export const confirmSchoolInvitingAction = createAsyncThunk<
  ConfirmSchoolInvitingResponse, void, AppThunkConfig
>(
  'schoolInviting/Confirm',
  (arg, thunkAPI) => bindAPI(confirmSchoolInviting, thunkAPI)(arg),
);

export const listSchoolTeachersAction = createAsyncThunk<
  ListSchoolTeachersResponse, ListSchoolTeacherParams, AppThunkConfig
>(
  'schoolTeacher/List',
  (arg, thunkAPI) => bindAPI(listSchoolTeachers, thunkAPI)(arg),
);

export const createSchoolTeacherAction = createAsyncThunk<
  CreateSchoolTeacherResponse, CreateSchoolTeacherParams, AppThunkConfig
>(
  'schoolTeacher/Create',
  (arg, thunkAPI) => bindAPI(createSchoolTeacher, thunkAPI)(arg),
);

export const deleteSchoolTeacherAction = createAsyncThunk<
  DeleteSchoolTeacherResponse, DeleteSchoolTeacherParams, AppThunkConfig
>(
  'schoolTeacher/Delete',
  (arg, thunkAPI) => bindAPI(deleteSchoolTeacher, thunkAPI)(arg),
);

export const listTeacherStudentsAction = createAsyncThunk<
  ListTeacherStudentsResponse, ListTeacherStudentsParams, AppThunkConfig
>(
  'teacherStudents/List',
  (arg, thunkAPI) => bindAPI(listTeacherStudents, thunkAPI)(arg),
);

export type SchoolState = {
  listMap: {
    [school_id: string]: School & Partial<SchoolDetail>|undefined;
  };
  listMembers: SchoolMember[];
  listIds: string[];
  modal: {
    visible: boolean;
    type: 'create'|'update'|'view',
    schoolId: string;
  };
  deleteAlert: {
    visible: boolean;
    schoolId: string;
  };
  deleteTeacherAlert: {
    visible: boolean;
    schoolId: string;
    roleBindingId?: string;
    invitingUserId?: string;
  };
  adminModal: {
    visible: boolean;
    schoolId: string;
  };
  studentModal: {
    visible: boolean;
    schoolId: string;
    teacherId: string;
  };
  listStudents: Student[];
};

const initialState: SchoolState = {
  listMap: {},
  listIds: [],
  listMembers: [],
  modal: {
    visible: false,
    type: 'create',
    schoolId: '',
  },
  deleteAlert: {
    visible: false,
    schoolId: '',
  },
  deleteTeacherAlert: {
    visible: false,
    schoolId: '',
    roleBindingId: '',
    invitingUserId: '',
  },
  adminModal: {
    visible: false,
    schoolId: '',
  },
  studentModal: {
    visible: false,
    schoolId: '',
    teacherId: '',
  },
  listStudents: [],
};

export const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    openSchoolModalAction(state: SchoolState, action: PayloadAction<{ type: 'create' }|{ schoolId: string; type: 'update'|'view' }>) {
      state.modal.type = action.payload.type;
      state.modal.visible = true;
      if (action.payload.type === 'update' || action.payload.type === 'view') {
        state.modal.schoolId = action.payload.schoolId;
      }
    },
    closeSchoolModalAction(state: SchoolState, action: PayloadAction<void>) {
      state.modal.schoolId = '';
      state.modal.visible = false;
    },
    openSchoolDeleteModalAction(state: SchoolState, action: PayloadAction<{ schoolId: string; }>) {
      state.deleteAlert.schoolId = action.payload.schoolId;
      state.deleteAlert.visible = true;
    },
    closeSchoolDeleteModalAction(state: SchoolState, action: PayloadAction<void>) {
      state.deleteAlert.schoolId = '';
      state.deleteAlert.visible = false;
    },
    openTeacherDeleteModalAction(state: SchoolState, action: PayloadAction<{ schoolId: string; roleBindingId?: string; invitingUserId?: string }>) {
      state.deleteTeacherAlert.schoolId = action.payload.schoolId;
      state.deleteTeacherAlert.roleBindingId = action.payload.roleBindingId;
      state.deleteTeacherAlert.invitingUserId = action.payload.invitingUserId;
      state.deleteTeacherAlert.visible = true;
    },
    closeTeacherDeleteModalAction(state: SchoolState, action: PayloadAction<void>) {
      state.deleteTeacherAlert.schoolId = '';
      state.deleteTeacherAlert.roleBindingId = '';
      state.deleteTeacherAlert.invitingUserId = '';
      state.deleteTeacherAlert.visible = false;
    },
    openSchoolAdminModalAction(state: SchoolState, action: PayloadAction<{ schoolId: string; }>) {
      state.adminModal.schoolId = action.payload.schoolId;
      state.adminModal.visible = true;
    },
    closeSchoolAdminModalAction(state: SchoolState, action: PayloadAction<void>) {
      state.adminModal.schoolId = '';
      state.adminModal.visible = false;
      state.listMembers = [];
    },
    openStudentModalAction(state: SchoolState, action: PayloadAction<Omit<SchoolState['studentModal'], 'visible'>>) {
      state.studentModal.schoolId = action.payload.schoolId;
      state.studentModal.teacherId = action.payload.teacherId;
      state.studentModal.visible = true;
    },
    closeStudentModalAction(state: SchoolState, action: PayloadAction<void>) {
      state.studentModal.schoolId = '';
      state.studentModal.teacherId ='';
      state.studentModal.visible = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listSchoolsAction.fulfilled, (state, action) => {
        state.listIds = action.payload.data.map(school => school.id);
        state.listMap = action.payload.data.reduce((result, school, index) => {
          return {
            ...result,
            [school.id]: school,
          };
        }, {});
      })
      .addCase(listSchoolAdminsAction.fulfilled, (state, action) => {
        state.listMembers = action.payload.data;
      })
      .addCase(listSchoolTeachersAction.fulfilled, (state, action) => {
        state.listMembers = action.payload.data;
      })
      .addCase(listTeacherStudentsAction.fulfilled, (state, action) => {
        state.listStudents = action.payload.data;
      })
      .addCase(getSchoolAction.fulfilled, (state, action) => {
        let school_id = action.meta.arg.id;
        state.listMap[school_id] = action.payload;
      })
      .addCase(logoutAction, (state, action) => initialState);
  },
});

export const {
  openSchoolModalAction,
  closeSchoolModalAction,
  openSchoolDeleteModalAction,
  closeSchoolDeleteModalAction,
  openTeacherDeleteModalAction,
  closeTeacherDeleteModalAction,
  openSchoolAdminModalAction,
  closeSchoolAdminModalAction,
  openStudentModalAction,
  closeStudentModalAction,
} = schoolSlice.actions;

export const selectListSchools = (state: RootState) => {
  return state.school.listIds.map((schoolId) => {
    return state.school.listMap[schoolId];
  }).filter((school): school is School => school !== undefined)
  .map(school => {
    return {
      ...school,
      can_read: selectCanReadSchoolInfo(state, school.id),
    };
  });
};

export const selectSchoolInfo = (state: RootState, schoolId: string) => {
  return state.school.listMap[schoolId];
}

export const selectSchoolInviteCodeLink = (state: RootState, schoolId: string) => {
  let school = selectSchoolInfo(state, schoolId);
  if (!school || !school.invite_code) {
    return '';
  }
  if (state.config.invite_code_uri) {
    return state.config.invite_code_uri.replace(':invite_code', school.invite_code);
  }
  return '';
};

export const selectSchoolModal = (state: RootState) => state.school.modal;

export const selectSchoolDeleteModal = (state: RootState) => state.school.deleteAlert;

export const selectTeacherDeleteModal = (state: RootState) => {
  const modal = state.school.deleteTeacherAlert;
  let teacherName = '';
  if (modal.roleBindingId) {
    const member = state.school.listMembers.find(
      member => 'role_binding_id' in member && member.role_binding_id === modal.roleBindingId
    );
    if (member) {
      if ('user_name' in member) {
        teacherName = member.user_name;
      } else {
        teacherName = member.user_email;
      }
    }
  } else {
    const member = state.school.listMembers.find(
      member => 'inviting_user_id' in member && member.inviting_user_id === modal.invitingUserId
    );
    teacherName = member?.user_email ?? '';
  }
  return {
    ...modal,
    teacherName,
  };
};

export const selectSchoolAdminModal = (state: RootState) => {
  return {
    ...state.school.adminModal,
    data: state.school.listMembers,
  };
}

export const selectSchoolTeachers = (state: RootState) => state.school.listMembers;

export const selectListStudentModal = (state: RootState) => {
  const modal = state.school.studentModal;
  return {
    ...modal,
    students: state.school.listStudents,
  };
};

export const selectOpenedStudentModalTeacherInfo = (state: RootState): InvitedSchoolMember|undefined => {
  return state.school.listMembers.find(
    (member) => {
      if ('user_id' in member) {
        return member.user_id === state.school.studentModal.teacherId;
      }
      return false;
    }
  ) as InvitedSchoolMember | undefined;
}
