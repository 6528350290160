import axios from 'axios';

import { ApiConfig, PagedQuery, PagedResult, ListResult, User, Parent, ParentDetail, School, SchoolDetail, SchoolMember, UserSchool, Student, GiftCardBatch, GiftCard } from 'types';
import { toSerializedError } from 'errors/utils';
import { stripEmptyString } from 'utils/mutate.util';
import _ from 'lodash';

export type GetProfileResponse = User;
export async function getProfile({ api_url, headers }: ApiConfig) {
  try {
    let res = await axios.get<GetProfileResponse>(api_url+'/api/users/me', {
      headers,
    });
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type ListUserSchoolsResponse = ListResult<UserSchool>;
export async function listUserSchools({ api_url, headers }: ApiConfig) {
  try {
    let res = await axios.get<ListUserSchoolsResponse>(api_url+'/api/users/me/schools', {
      headers,
    });
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type ConfirmSchoolInvitingResponse = { request_id: string; };
export async function confirmSchoolInviting({ api_url, headers }: ApiConfig) {
  try {
    let res = await axios.put<ConfirmSchoolInvitingResponse>(api_url+'/api/users/me/inviting/confirm', undefined, {
      headers,
    });
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export interface ListParentsQuery extends PagedQuery {
  email?: string[];
};
export interface ListParentsResponse extends PagedResult<Parent> {};
export async function listParents({ api_url, headers }: ApiConfig, query: ListParentsQuery) {
  try {
    let res = await axios.get<ListParentsResponse>(api_url+'/api/parents', {
      headers,
      params: query,
    });
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type GetParentParams = {
  parent_id: string;
};
export type GetParentResponse = ParentDetail;
export async function getParent({ api_url, headers }: ApiConfig, query: GetParentParams) {
  try {
    let res = await axios.get<GetParentResponse>(api_url+'/api/parents/'+query.parent_id, {
      headers,
    });
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type UpdateParentParams = {
  parent_id: string;
  body: Pick<ParentDetail, 'quota'|'state'>;
};
export type UpdateParentResponse = { request_id: string; };
export async function updateParent({ api_url, headers }: ApiConfig, { parent_id, body }: UpdateParentParams) {
  try {
    let res = await axios.put<UpdateParentResponse>(api_url+'/api/parents/'+parent_id, body, {
      headers,
    });
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type ListSchoolsResponse = {
  data: School[];
}
export async function listSchools({ api_url, headers }: ApiConfig) {
  try {
    let res = await axios.get<ListSchoolsResponse>(api_url+'/api/schools', {
      headers,
    });
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type CreateSchoolParams = Pick<School, 'name'|'state'>;
export type CreateSchoolResponse = {request_id: string;};
export async function createSchool({ api_url, headers }: ApiConfig, body: CreateSchoolParams) {
  try {
    let res = await axios.post<CreateSchoolResponse>(api_url+'/api/schools',
      stripEmptyString(body),
      { headers }
    );
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type GetSchoolParams = Pick<School, 'id'>;
export type GetSchoolResponse = SchoolDetail;
export async function getSchool({ api_url, headers }: ApiConfig, {id}: GetSchoolParams) {
  try {
    let res = await axios.get<GetSchoolResponse>(api_url+'/api/schools/'+id,
      { headers }
    );
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type UpdateSchoolParams = Omit<School, 'quota_usage'>;
export type UpdateSchoolResponse = {request_id: string;};
export async function updateSchool({ api_url, headers }: ApiConfig, {id, ...body}: UpdateSchoolParams) {
  try {
    let res = await axios.put<UpdateSchoolResponse>(api_url+'/api/schools/'+id,
      stripEmptyString(body),
      { headers }
    );
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type DeleteSchoolParams = Pick<School, 'id'>;
export type DeleteSchoolResponse = {request_id: string;};
export async function deleteSchool({ api_url, headers }: ApiConfig, {id}: DeleteSchoolParams) {
  try {
    let res = await axios.delete<DeleteSchoolResponse>(api_url+'/api/schools/'+id, {
      headers,
    });
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type ListSchoolAdminParams = Pick<School, 'id'>;
export type ListSchoolAdminsResponse = {
  data: SchoolMember[];
};
export async function listSchoolAdmins({ api_url, headers }: ApiConfig, {id}: ListSchoolAdminParams) {
  try {
    let res = await axios.get<ListSchoolAdminsResponse>(api_url+'/api/schools/'+id+'/admins',
      { headers }
    );
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type CreateSchoolAdminParams = {id: string; user_email: string;};
export type CreateSchoolAdminResponse = {
  request_id: string;
  role_binding_id: string;
} | {
  request_id: string;
  inviting_user_id: string;
};
export async function createSchoolAdmin({ api_url, headers }: ApiConfig, {id, ...body}: CreateSchoolAdminParams) {
  try {
    let res = await axios.post<CreateSchoolAdminResponse>(api_url+'/api/schools/'+id+'/admins',
      stripEmptyString(body),
      { headers }
    );
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type DeleteSchoolAdminParams = {
  id: string;
  role_binding_id: string;
} | {
  id: string;
  inviting_user_id: string;
};
export type DeleteSchoolAdminResponse = {request_id: string;};
export async function deleteSchoolAdmin({ api_url, headers }: ApiConfig, {id, ...query}: DeleteSchoolAdminParams) {
  try {
    let res = await axios.delete<DeleteSchoolResponse>(api_url+'/api/schools/'+id+'/admins', {
      headers,
      params: query,
    });
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type ListSchoolTeacherParams = Pick<School, 'id'>;
export type ListSchoolTeachersResponse = {
  data: SchoolMember[];
};
export async function listSchoolTeachers({ api_url, headers }: ApiConfig, {id}: ListSchoolTeacherParams) {
  try {
    let res = await axios.get<ListSchoolTeachersResponse>(api_url+'/api/schools/'+id+'/teachers',
      { headers }
    );
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type CreateSchoolTeacherParams = {id: string; user_email: string;};
export type CreateSchoolTeacherResponse = {
  request_id: string;
  role_binding_id: string;
} | {
  request_id: string;
  inviting_user_id: string;
};
export async function createSchoolTeacher({ api_url, headers }: ApiConfig, {id, ...body}: CreateSchoolTeacherParams) {
  try {
    let res = await axios.post<CreateSchoolTeacherResponse>(api_url+'/api/schools/'+id+'/teachers',
      stripEmptyString(body),
      { headers }
    );
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type DeleteSchoolTeacherParams = {
  id: string;
  role_binding_id: string;
} | {
  id: string;
  inviting_user_id: string;
};
export type DeleteSchoolTeacherResponse = {request_id: string;};
export async function deleteSchoolTeacher({ api_url, headers }: ApiConfig, {id, ...query}: DeleteSchoolTeacherParams) {
  try {
    let res = await axios.delete<DeleteSchoolResponse>(api_url+'/api/schools/'+id+'/teachers', {
      headers,
      params: _.omitBy(query, _.isUndefined),
    });
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type ListTeacherStudentsParams = {
  school_id: School['id'];
  teacher_id: string;
};

export type ListTeacherStudentsResponse = {
  data: Student[];
};

export async function listTeacherStudents({ api_url, headers }: ApiConfig, params: ListTeacherStudentsParams) {
  try {
    let res = await axios.get<ListTeacherStudentsResponse>(
      api_url+'/api/schools/'+params.school_id+'/teachers/'+params.teacher_id+'/students',
      { headers }
    );
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export interface ListGiftCardBatchQuery extends PagedQuery {
  id?: string[];
};
export interface ListGiftCardBatchResponse extends PagedResult<GiftCardBatch> {};
export async function listGiftCardBatch({ api_url, headers }: ApiConfig, query: ListGiftCardBatchQuery) {
  try {
    let res = await axios.get<ListGiftCardBatchResponse>(
      `${api_url}/api/gift-card-batch`, {
        headers,
        params: query,
      }
    );
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type ListGiftCardQuery = {
  batch_id?: string;
};
export type ListGiftCardResponse = {
  data: GiftCard[];
};
export async function listGiftCard({ api_url, headers }: ApiConfig, query: ListGiftCardQuery) {
  try {
    let res = await axios.get<ListGiftCardResponse>(
      `${api_url}/api/gift-cards`, {
        headers,
        params: query,
      }
    );
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type CreateGiftCardBatchBody = Pick<GiftCardBatch,
  'name' | 'productId' | 'qty' | 'unit_qty' | 'description' | 'expired_ts'
>;
export type CreateGiftCardBatchResponse = {
  id: string;
  exchanged_password: string;
  request_id: string;
};
export async function createGiftCardBatch({ api_url, headers }: ApiConfig, body: CreateGiftCardBatchBody) {
  try {
    let res = await axios.post<CreateGiftCardBatchResponse>(
      `${api_url}/api/gift-card-batch`, body,
      { headers }
    );
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}

export type DisableGiftCardQuery = Partial<Pick<GiftCard,
  'no' | 'batch_id'
>>;
export type DisableGiftCardResponse = {
  request_id: string;
};
export async function disableGiftCard({ api_url, headers }: ApiConfig, query: DisableGiftCardQuery) {
  try {
    let res = await axios.put<DisableGiftCardResponse>(
      `${api_url}/api/gift-cards/disable`, undefined,
      { headers, params: query }
    );
    return res.data;
  } catch(err) {
    throw toSerializedError(err);
  }
}
